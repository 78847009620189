import { Capacitor } from '@capacitor/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';

const CheckPermissions = {
  requestCameraPermission() {
    if (Capacitor.isNativePlatform()) {
      AndroidPermissions.checkPermission(
        AndroidPermissions.PERMISSION.CAMERA
      ).then((result: any) => {
        if (!result.hasPermission) {
          AndroidPermissions.requestPermission(
            AndroidPermissions.PERMISSION.CAMERA
          );
        } else {
          CheckPermissions.requestCameraPermission();
        }
      });
    } else {
      console.log("Capacitor not detected, this button will do nothing :(");
    }
  },
  requestFineLocation() {
    if (Capacitor.isNativePlatform()) {
      AndroidPermissions.checkPermission(
        AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION
      ).then((result: any) => {
        if (!result.hasPermission) {
          AndroidPermissions.requestPermission(
            AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION
          );
        } else {
          CheckPermissions.requestFineLocation();
        }
      });
    } else {
      console.log("Capacitor not detected, this button will do nothing :(");
    }
  },
  requestFilesystem() {
    if (Capacitor.isNativePlatform()) {
      AndroidPermissions.checkPermission(
        AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE
      ).then((result: any) => {
        if (!result.hasPermission) {
          AndroidPermissions.requestPermissions([
            AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
            AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
          ]);
        } else {
          CheckPermissions.requestFilesystem();
        }
      });
    } else {
      console.log("Capacitor not detected, this button will do nothing :(");
    }
  }
};

export default CheckPermissions;
