
import 
  {
    IonCard, IonCol, IonIcon, IonLabel, IonListHeader, IonRow, IonSkeletonText, IonText, useIonAlert,  IonRefresher, IonRefresherContent, useIonToast,
    IonCardHeader,
    IonModal,
    IonCardTitle,
    IonContent,
    IonCardContent,
    IonCardSubtitle,
  }
from '@ionic/react';
import React, { useRef, useState }  from 'react';
import { OverlayEventDetail, RefresherEventDetail } from '@ionic/core';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { chevronBackCircleOutline, chevronDownCircleOutline, chevronForwardCircleOutline, informationCircleOutline, sadOutline } from 'ionicons/icons';
import { getNameDate, Rupiah } from '../../services/Library';
import { Target1, Achievement, Target2 } from '../../models/Data';
import { RestApi } from '../../services/Api';
import { useAuth } from '../../data/sessions/Auth';
import { useTranslation } from 'react-i18next';
import './Tabel.scss';

const columnHelperTarget1 = createColumnHelper<Target1>();
const columnHelperTarget2 = createColumnHelper<Target2>();
const columnHelperAchievement = createColumnHelper<Achievement>()

const columnsSalesTarget1: any = [
  columnHelperTarget1.accessor("description", {
    header: () => <p className="ion-text-center">Description</p>,
    cell: (info) => <p>{info.renderValue()}</p>,
  }),
  columnHelperTarget1.accessor("target", {
    header: () => <p className="ion-text-center">Target</p>,
    cell: (info) => <p className="ion-text-end">{info.renderValue()}</p>,
  }),
  columnHelperTarget1.accessor("sales", {
    header: () => <p className="ion-text-center">Sales</p>,
    cell: (info) => <p className="ion-text-end">{info.renderValue()}</p>,
  }),
  columnHelperTarget1.accessor("ach", {
    header: () => <p className="ion-text-center">Ach%</p>,
    cell: (info) => <p className="ion-text-end">{info.renderValue()}</p>,
  }),
];

const columnsSalesTarget2: any = [
  columnHelperTarget2.accessor("name", {
    header: () => <p className="ion-text-center">Produk</p>,
    cell: (info) => (
      <p>
        {info.renderValue()}
        <IonIcon size="small" icon={informationCircleOutline} color="danger" />
      </p>
    ),
  }),
  columnHelperTarget2.accessor("quantity", {
    header: () => <p className="ion-text-center">Qty</p>,
    cell: (info) => <p className="ion-text-end">{Number(info.renderValue())}</p>,
  }),

  columnHelperTarget2.accessor("amount", {
    header: () => <p className="ion-text-center">Amount</p>,
    cell: (info) => <p className="ion-text-end">{Rupiah(info.renderValue()||0)}</p>,
  }),
  columnHelperTarget2.accessor("target", {
    header: () => <p className="ion-text-center">Budget</p>,
    cell: (info) => <p className="ion-text-end">{Number(info.renderValue())}</p>,
  }),
  columnHelperTarget2.accessor("total", {
    header: () => <p className="ion-text-center">Incentive</p>,
    cell: (info) => (
      <p className="ion-text-end">{Rupiah(info.renderValue() || 0)}</p>
    ),
  }),
];

const columnsAchievement : any = [
  columnHelperAchievement.accessor('paymentSystem', {
    header: () => <p className='ion-text-center'>Payment System</p>,
    cell: info => <p>{info.renderValue()}</p>,
  }),
  columnHelperAchievement.accessor('duedate', {
    header: () => <p className='ion-text-center'>JT</p>,
    cell: info => <p className='ion-text-end ion-text-uppercase'>{info.renderValue()}</p>,
  }),
  columnHelperAchievement.accessor('allowance', {
    header: () => <p className='ion-text-center'>Alw</p>,
    cell: info => <p className='ion-text-end'>{info.renderValue()}</p>,
  }),
  columnHelperAchievement.accessor('duedateAndAllowace', {
    cell: info => <p className='ion-text-center'>{info.renderValue()}</p>,
    header: () => <p className='ion-text-end'>{'>=JT&<=Alw'}</p>,
  }),
  columnHelperAchievement.accessor('totalAllowance', {
    header: () => <p className='ion-text-center'>{'>Alw'}</p>,
    cell: info => <p className='ion-text-end'>{info.renderValue()}</p>,
  })
]

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Tabel: React.FC = () => {
    const { t } = useTranslation(["general"]);
    const [alert] = useIonAlert();
    const { logOut } = useAuth()!;
    const [presentToast] = useIonToast();
    const today = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const [dataSalesType1, setDataSalesType1] = useState<any>([]);
    const [dataSalesType2, setDataSalesType2] = useState<any>([]);
    const [dataAchievement, setDataAchievement] = useState<any>([]);
    const [totalAchAmount, setTotalAchAmount] = useState<number>(0);
    const [totalAchQty, setTotalAchQty] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [incentive, setIncentive] = useState<any>([]);
    const [indexIncentive2, setIndexIncentive2] = useState<any>([]);
    const [detailIncentive2, setDetailIncentive2] = useState<any>({
      detail : []
    });
    const [searchDate, setSearchDate] = useState<any>(null);
    const modalIndexIncentive2 = useRef<HTMLIonModalElement>(null);
    const [showModalIndexIncentive2, setShowModalIndexIncentive2] = useState<boolean>(false);

    const massageAlert = async (status:string,body:string) => {
      alert({
          header: t(status),
          message: body,
          cssClass: `header-alert-${status}`,
          buttons: [{cssClass: 'alert-button-confirm', text: 'Ok' }],
      })
    }
    
    const getExactIncentive = async (date:string) => {
        setLoaded(false)
        await RestApi('incentive/exact-incentive?date='+date,'get',{},'application/json')
        .then((res:any) => {
            setDataSalesType1([]);
            setDataSalesType2([]);
            setDataAchievement([]);
            if (res.data.code === 200) {
              switch (res.data.data.gtype_type) {
                case 1:
                  res.data.data.incentivedet.map((item:any, i:number) => {
                    if (Number(item.incentivedet_sales_quantity) !== 0) {
                      return setDataSalesType1((data: any) => [...data, {
                        description: item.grouping_name,
                        target: Number(item.incentivedet_target_quantity) !== 0 ? Math.round(Number(item.incentivedet_target_quantity))   : Rupiah(item.incentivedet_target_amount),
                        sales: Number(item.incentivedet_target_quantity) !== 0 ? Math.round(Number(item.incentivedet_sales_quantity))  : Rupiah(item.incentivedet_sales_amount),
                        ach: item.incentivedet_ach_rate,
                      }])
                    }else{
                      return '';
                    }
                  });
                  break;
                case 2:
                  res.data.data.incentivedet.map((item:any, i:number) => {
                    let name = item.incentivedet_grouping_name.split("-");
                    return setDataSalesType2((data: any) => [
                      ...data,
                      {
                        name: name[0],
                        type: name[1].toUpperCase(),
                        budget:
                          name[1] === "amount"
                            ? item.incentivedet_budget_amount
                            : item.incentivedet_budget_rate,
                        quantity: Number(item.incentivedet_sales_quantity),
                        amount: item.incentivedet_sales_amount,
                        sales:
                          name[1] === "amount"
                            ? Rupiah(item.incentivedet_sales_amount)
                            : Number(item.incentivedet_sales_quantity),
                        target:
                          name[1] === "amount"
                            ? item.incentivedet_ach_rate
                            : item.incentivedet_target_quantity,
                        total: item.incentivedet_ach_subtotal,
                      },
                    ]);
                  })

                  setTotalAchAmount(
                    res.data.data.incentivedet.reduce(
                      (n: number, { incentivedet_sales_amount }: any) =>
                        n + Number(incentivedet_sales_amount),
                      0
                    )
                  );
                  setTotalAchQty(
                    res.data.data.incentivedet.reduce(
                      (n: number, { incentivedet_sales_quantity }: any) =>
                        n + Number(incentivedet_sales_quantity),
                      0
                    )
                  );
                  break;
                default:
                  massageAlert("error", "Tidak Ada Data Incentive");
                  setLoaded(true);
                  return;
              }
              if (res.data.data.incentive_term) {
                let totalOverdue = 0;
                let totalPayment = 0;
                res.data.data.incentive_term.map((item:any, i:number) => {
                    totalOverdue += Number(item.incentive_term_overdue)
                    totalPayment += Number(item.incentive_term_limit)
                    return setDataAchievement((data: any) => [...data, {
                      paymentSystem: item.incentive_term_name,
                      duedate: item.incentive_term_days,
                      allowance: item.incentive_term_limit_days,
                      duedateAndAllowace: Rupiah(item.incentive_term_overdue),
                      totalAllowance: Number(item.incentive_term_overdue) !== 0 ? 0 : Rupiah(item.incentive_term_limit)
                    }])
                })
                res.data.data.incentive_payment.map((item:any, i:number) => {
                    return setDataAchievement((data: any) => [...data, {
                      paymentSystem: '',
                      duedate: `${item.payment_name}`,
                      allowance: ``,
                      duedateAndAllowace: Rupiah(item.payment_amount),
                      totalAllowance: ''
                    }])
                })
                setDataAchievement((data: any) => [...data, {
                  paymentSystem: '',
                  duedate: 'Subtotal Payment System',
                  allowance: '',
                  duedateAndAllowace: Rupiah(totalOverdue),
                  totalAllowance: Rupiah(totalPayment),
                }])
                setDataAchievement((data: any) => [...data, {
                  paymentSystem: '',
                  duedate: 'Achievement (%)',
                  allowance: '',
                  duedateAndAllowace: '',
                  totalAllowance: res.data.data.incentive_ar_ach_rate,
                }])
              }
            }else{
              getIncentive(`${date}`)
              presentToast({
                message: res.data.message,
                duration: 1500,
                color : "danger",
                icon: sadOutline
              })
              if (res.data.message.indexOf("Silahkan") > 0) {
                massageAlert('error', res.data.message);
                logOut();
              }
            }
            setLoaded(true)
        })
        .catch((err:any) => {
          massageAlert('error',err.message)
          setLoaded(true)
        });
    }
    
    const getIncentive = async (date:string) => {
        setLoaded(false)
        await RestApi("incentive?date=" + date, "get", {}, "application/json")
          .then((res: any) => {
            setDataSalesType1([]);
            setDataSalesType2([]);
            setDataAchievement([]);
            if (res.data.code === 200) {
              setIncentive(res.data.data);
              switch (res.data.data.gtype[0].gtype_type) {
                case 1:
                  setDataSalesType1(res.data.data.incentivedet);
                  break;
                case 2:
                  setDataSalesType2(res.data.data.incentivedet);
                  setTotalAchAmount(res.data.data.incentivedet.reduce(
                      (n: number, { amount }: any) =>
                        n + Number(amount),
                      0
                    )
                  )
                  setTotalAchQty(
                    res.data.data.incentivedet.reduce(
                      (n: number, { quantity }: any) => n + Number(quantity),
                      0
                    )
                  );
                  break;
                default:
                  massageAlert("error", "Tidak Ada Data Incentive");
                  setLoaded(true);
                  return;
              }
              if (res.data.data.incentive_term) {
                let totalOwing = 0;
                let totalOver = 0;
                Object.keys(res.data.data.incentive_term).map(
                  (item: any, i: number) => {
                    totalOwing += Number(
                      res.data.data.incentive_term[item].under_limit
                    );
                    totalOver += Number(
                      res.data.data.incentive_term[item].over_limit
                    );
                    return setDataAchievement((data: any) => [
                      ...data,
                      {
                        paymentSystem:
                          res.data.data.incentive_term[item].term_name,
                        duedate: res.data.data.incentive_term[item].term_days,
                        allowance:
                          res.data.data.incentive_term[item].term_limit_days,
                        duedateAndAllowace: Rupiah(
                          res.data.data.incentive_term[item].under_limit
                        ),
                        totalAllowance: Rupiah(
                          res.data.data.incentive_term[item].over_limit
                        ),
                      },
                    ]);
                  }
                );
                let totalPayment = 0;
                if (res.data.data.ar_payment.normal_payment) {
                  totalPayment += Number(
                    res.data.data.ar_payment.normal_payment
                  );
                  setDataAchievement((data: any) => [
                    ...data,
                    {
                      paymentSystem: "",
                      duedate: "Normal Payment",
                      allowance: ``,
                      duedateAndAllowace: Rupiah(
                        res.data.data.ar_payment.normal_payment
                      ),
                      totalAllowance: "",
                    },
                  ]);
                }
                if (res.data.data.ar_payment.good_payment) {
                  totalPayment += Number(res.data.data.ar_payment.good_payment);
                  setDataAchievement((data: any) => [
                    ...data,
                    {
                      paymentSystem: "",
                      duedate: "Good Payment",
                      allowance: ``,
                      duedateAndAllowace: Rupiah(
                        res.data.data.ar_payment.good_payment
                      ),
                      totalAllowance: "",
                    },
                  ]);
                }
                setDataAchievement((data: any) => [
                  ...data,
                  {
                    paymentSystem: "",
                    duedate: "Subtotal Payment System",
                    allowance: "",
                    duedateAndAllowace: Rupiah(totalOwing + totalPayment),
                    totalAllowance: Rupiah(totalOver),
                  },
                ]);
                let arAchRate = 0;
                if (totalOwing > 0 || totalPayment > 0)
                  arAchRate = (totalOver / (totalOwing + totalPayment)) * 100;

                setDataAchievement((data: any) => [
                  ...data,
                  {
                    paymentSystem: "",
                    duedate: "Achievement (%)",
                    allowance: "",
                    duedateAndAllowace: "",
                    totalAllowance: arAchRate.toFixed(2),
                  },
                ]);
              }
            } else {
              presentToast({
                message: res.data.message,
                duration: 1500,
                color: "danger",
                icon: sadOutline,
              });
            }
            setLoaded(true);
          })
          .catch((err: any) => {
            massageAlert("error", err.message);
            setLoaded(true);
          });
    }
    
    const tableTarget1 = useReactTable({
      data : dataSalesType1,
      columns : columnsSalesTarget1,
      getCoreRowModel: getCoreRowModel(),
    })

    const tableTarget2 = useReactTable({
      data: dataSalesType2,
      columns: columnsSalesTarget2,
      getCoreRowModel: getCoreRowModel(),
    });

    const tableAchievement = useReactTable({
      data : dataAchievement,
      columns : columnsAchievement,
      getCoreRowModel: getCoreRowModel(),
    })

    const currentDate = (param:number) =>{
      let currentDate = new Date(searchDate)
      setIndexIncentive2([])
      setDetailIncentive2({
        detail: [],
      });
      switch (param) {
        case 1:
          let nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
          setSearchDate(nextDate.toISOString().slice(0, 10))
          if (nextDate.toDateString() === today.toDateString()) {
            getIncentive(`${today.toISOString().slice(0, 10)}`)
            return;
          }
          getExactIncentive(`${nextDate.toISOString().slice(0, 10)}`)
          break;
        case 2:
          let backDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), - 1);
          setSearchDate(backDate.toISOString().slice(0, 10))
          if (backDate.toDateString() === today.toDateString()) {
            getIncentive(`${today.toISOString().slice(0, 10)}`)
            return;
          }
          getExactIncentive(`${backDate.toISOString().slice(0, 10)}`)
          break;
        default:
          break;
      }
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
      setSearchDate(today.toISOString().slice(0, 10))
      getIncentive(`${today.toISOString().slice(0, 10)}`)
      event.detail.complete();
    }

    const showIndexIncentive2 = (data:any) => {
      let listIndex = incentive.gtype.filter((obj:any) => obj.gtype_grouping_name === data.original.name).sort((a:any,b:any) => (Number(a.gtype_grouping_rate) > Number(b.gtype_grouping_rate)  ? 1 : -1));
      if (!listIndex.length) {
        massageAlert("error", "Tidak Ada Data Incentive");
        return;
      }
      setIndexIncentive2(listIndex);
      setDetailIncentive2(incentive.incentivedet.find((obj:any) => obj.name === data.original.name));
      setShowModalIndexIncentive2(true);
    };

    const onWillDismissIndexIncentive2 = (ev: CustomEvent<OverlayEventDetail>) => {
      setShowModalIndexIncentive2(false)
      modalIndexIncentive2.current?.dismiss();
    };

    useConstructor(() => {
      setSearchDate(today.toISOString().slice(0, 10))
      getIncentive(`${today.toISOString().slice(0, 10)}`)
    });

    return (
      <IonRow>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        <IonCol size="12" className="ion-no-padding">
          <IonCard className="ion-no-padding">
            <IonRow className="ion-no-padding">
              <IonCol size="2" className="ion-no-padding">
                <br />
                <IonText color="dark" className="ion-no-padding">
                  <IonIcon
                    size="large"
                    className="ion-padding ion-text-left"
                    icon={chevronBackCircleOutline}
                    onClick={() => {
                      currentDate(2);
                    }}
                  />
                </IonText>
              </IonCol>
              <IonCol size="8">
                {loaded ? (
                  <IonLabel
                    color="dark"
                    className="ion-text-center ion-no-padding"
                  >
                    <p>{getNameDate()}</p>
                    <h2>
                      <strong>{searchDate.substring(0, 7)}</strong>
                    </h2>
                    <p>{incentive.salesmanArea}</p>
                  </IonLabel>
                ) : (
                  <IonListHeader>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "100%" }}
                    ></IonSkeletonText>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "100%" }}
                    ></IonSkeletonText>
                  </IonListHeader>
                )}
              </IonCol>
              <IonCol size="2" className="ion-no-padding">
                <IonText color="dark" className="ion-no-padding">
                  <br />
                  <IonIcon
                    size="large"
                    className="ion-padding ion-text-right"
                    icon={chevronForwardCircleOutline}
                    onClick={() => {
                      currentDate(1);
                    }}
                  />
                </IonText>
              </IonCol>
            </IonRow>
          </IonCard>
        </IonCol>
        {loaded ? (
          <IonCol size="12" className="ion-no-padding ion-no-margin">
            <IonCard className="ion-no-padding">
              {dataSalesType2.length > 0 ? (
                <IonCardHeader
                  color="medium"
                  className="ion-text-center ion-no-padding ion-no-margin"
                >
                  <IonText
                    color={"dark"}
                    className="ion-text-center ion-no-padding ion-no-margin"
                  >
                    <p>
                      <strong>{incentive.gtype_name}</strong> <br />
                      <strong>
                        Target Amount : {Rupiah(incentive.gtype_budget_amount)}
                      </strong>
                    </p>
                  </IonText>
                </IonCardHeader>
              ) : (
                ""
              )}
              {dataSalesType1.length > 0 ? (
                <table className="ion-no-padding ion-no-margin">
                  <thead>
                    {tableTarget1.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableTarget1.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}

              {dataSalesType2.length > 0 ? (
                <table className="ion-no-padding ion-no-margin">
                  <thead>
                    {tableTarget2.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableTarget2.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        onClick={() => {
                          showIndexIncentive2(row);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="ion-text-center">
                        <p>
                          <strong>Total</strong>
                        </p>
                      </td>
                      <td className="ion-text-end">
                        <p>
                          <strong>&nbsp;{Rupiah(totalAchQty)}&nbsp;</strong>
                        </p>
                      </td>
                      <td className="ion-text-end">
                        <p>
                          <strong> &nbsp;{Rupiah(totalAchAmount)}&nbsp;</strong>
                        </p>
                      </td>
                      <td className="ion-text-end" colSpan={2}>
                        <p>
                          <strong>
                            &nbsp;
                            {totalAchAmount >=
                            Number(incentive.gtype_budget_amount)
                              ? Rupiah(
                                  dataSalesType2.reduce(
                                    (n: number, { total }: any) =>
                                      n + Number(total),
                                    0
                                  )
                                )
                              : "-"}
                            &nbsp;
                          </strong>
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                ""
              )}
            </IonCard>
            <IonCard className="ion-no-padding">
              {dataAchievement.length > 0 ? (
                <table className="ion-no-padding ion-no-margin">
                  <thead>
                    {tableAchievement.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableAchievement.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </IonCard>
          </IonCol>
        ) : (
          <IonCol size="12">
            <IonCard className="ion-no-padding" style={{ height: "2000%" }}>
              <IonSkeletonText
                animated={true}
                className="ion-no-padding"
                style={{ height: "1000%" }}
              ></IonSkeletonText>
            </IonCard>
          </IonCol>
        )}
        <IonModal
          ref={modalIndexIncentive2}
          isOpen={showModalIndexIncentive2}
          initialBreakpoint={detailIncentive2.detail.length ? 0.6 : 0.25}
          breakpoints={[0, 1.0]}
          handleBehavior="cycle"
          onWillDismiss={(ev) => onWillDismissIndexIncentive2(ev)}
        >
          <IonContent>
            {indexIncentive2.length && (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">
                    {indexIncentive2[0].gtype_grouping_name}
                  </IonCardTitle>
                  <IonCardSubtitle className="ion-text-center">
                    {indexIncentive2[0].gtype_grouping_type.toUpperCase()}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding ion-no-margin">
                  <table className="ion-no-padding ion-no-margin">
                    <thead>
                      <tr>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Index</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Target</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Budget</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {indexIncentive2.map((value: any, key: any) => (
                        <tr key={key}>
                          <td className="ion-text-center">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>{key + 1}</p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                              {value.gtype_grouping_type === "amount"
                                ? Rupiah(value.gtype_grouping_budget)
                                : Number(value.gtype_grouping_budget)}
                            </p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                              {value.gtype_grouping_type === "amount"
                                ? Number(value.gtype_grouping_rate)
                                : Rupiah(value.gtype_grouping_rate)}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </IonCardContent>
              </IonCard>
            )}
            {detailIncentive2.detail.length && (
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">
                    Detail Grouping
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding ion-no-margin">
                  <table className="ion-no-padding ion-no-margin">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th colSpan={2}>SO</th>
                        <th colSpan={2}>SI</th>
                      </tr>
                      <tr>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Produk</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Target</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Qty</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Amount</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Qty</p>
                        </th>
                        <th>
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Amount</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailIncentive2.detail.map((value: any, key: any) => (
                        <tr key={key}>
                          <td className="ion-text-center">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>{value.target?.grouping_name ?? "-"}</p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                              {value.target?.target_amount === "0.00"
                                ? Number(value.target?.target_quantity ?? 0)
                                : Rupiah(value.target?.target_amount ?? 0)}
                            </p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>{Number(value.so_quantity)}</p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>{Rupiah(value.so_amount)}</p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>{Number(value.si_quantity)}</p>
                          </td>
                          <td className="ion-text-end">
                            <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>{Rupiah(value.si_amount)}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th className="ion-text-end">
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>Total</p>
                        </th>
                        <th className="ion-text-end">
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                            {detailIncentive2.detail.reduce(
                              (n: number, value: any) => {
                                return value.target?.target_amount === "0.00"
                                  ? Number(
                                      n +
                                        Number(
                                          value.target?.target_quantity ?? 0
                                        )
                                    )
                                  : Rupiah(
                                      n +
                                        Number(value.target?.target_amount ?? 0)
                                    );
                              },
                              0
                            )}
                          </p>
                        </th>
                        <th className="ion-text-end">
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                            {Number(
                              detailIncentive2.detail.reduce(
                                (
                                  n: number,
                                  { so_quantity }: { so_quantity: number }
                                ) => n + Number(so_quantity),
                                0
                              )
                            )}
                          </p>
                        </th>
                        <th className="ion-text-end">
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                            {Rupiah(
                              detailIncentive2.detail.reduce(
                                (
                                  n: number,
                                  { so_amount }: { so_amount: number }
                                ) => n + Number(so_amount),
                                0
                              )
                            )}
                          </p>
                        </th>
                        <th className="ion-text-end">
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                            {Number(
                              detailIncentive2.detail.reduce(
                                (
                                  n: number,
                                  { si_quantity }: { si_quantity: number }
                                ) => n + Number(si_quantity),
                                0
                              )
                            )}
                          </p>
                        </th>
                        <th className="ion-text-end">
                          <p style={{
                              fontSize: "12px",
                              padding: "0px 5px 0px 5px",
                          }}>
                            {Rupiah(
                              detailIncentive2.detail.reduce(
                                (
                                  n: number,
                                  { si_amount }: { si_amount: number }
                                ) => n + Number(si_amount),
                                0
                              )
                            )}
                          </p>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </IonCardContent>
              </IonCard>
            )}
          </IonContent>
        </IonModal>
      </IonRow>
    );
}

export default Tabel;
