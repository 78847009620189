import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  useIonAlert,
  IonButton,
  IonCard,
  IonCardHeader,
  IonItem,
  IonFooter,
  IonText,
  useIonLoading,
  useIonToast,
  IonCardContent,
  IonTextarea,
  IonRow,
  IonCol,
} from "@ionic/react";
import {
  arrowBackOutline,
  sadOutline,
  warningOutline,
} from "ionicons/icons";
import React, {  useState } from "react";
import { useTranslation } from "react-i18next";
import { RestApi } from "../../services/Api";
import { Rupiah } from "../../services/Library";
import { CartAction } from "../../data/state/CartReducer";
import { useDispatch } from "react-redux";
import { SoCheckout } from "../../models/Modal";
import "./Checkout.scss";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const Checkout: React.FC<SoCheckout> = (props) => {
  const [alert] = useIonAlert();
  const dispatch = useDispatch();
  const [presentToast] = useIonToast();
  const { t } = useTranslation(["general"]);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [so, setSo] = useState<any>(props.so);
  const [customer, setCustomer] = useState<any>(props.customer);

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [
        {
          cssClass: "alert-button-confirm",
          text: "Ok",
        },
      ],
    });
  };

  const createSo = async () => {
    if (!so.so_remark) {
      presentToast({
        message: `${t("required")} ${t("add-note")}`,
        color: "danger",
        position: "middle",
        duration: 2500,
        icon: sadOutline,
      });
      return;
    }
    await presentLoading({ message: t("loading") });
    await RestApi(props.type === "create" ? "so" : `so/${so.so_id}`, "post", so)
      .then(async (res: any) => {
        if (res.data.code === 200) {
          massageAlert("success", res.data.message);
          dispatch(CartAction.clearItem(true));
          dismissLoading();
          props.onDismiss(true);
        } else {
          presentToast({
            message: res.data.message,
            duration: 2500,
            color: "danger",
            icon: warningOutline,
            position: "middle",
          });
          dismissLoading();
        }
      })
      .catch((error: any) => {
        presentToast({
          message: error.message,
          duration: 2500,
          color: "danger",
          icon: warningOutline,
          position: "middle",
        });
        dismissLoading();
      });
  };

  useConstructor(async () => {
    setSo(props.so);
    setCustomer(props.customer);
  });

  return (
    <IonPage>
      <IonHeader className="ion-justify-content-between">
        <IonToolbar>
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => {
              props.onDismiss(false);
            }}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonText color="light">
            <strong>{t("cart-checkout")}</strong>
          </IonText>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonContent className="ion-no-padding ion-no-margin">
          <br />
          <IonCard className="ion-no-padding ion-no-margin">
            <IonCardHeader className="ion-no-padding ion-margin-start">
              <IonText color={"dark"}>
                <strong>{t("shipping-destination")}</strong>
                <hr />
              </IonText>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding ion-margin-start">
              <IonItem className="ion-no-padding ion-no-margin">
                <p
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <strong>{customer.customer_name}</strong>,{" "}
                  {customer.customer_id}
                  <br />
                  {`${customer.customer_address1} ${customer.customer_address2}`}
                </p>
              </IonItem>
            </IonCardContent>
          </IonCard>
          <br />
          <IonCard className="ion-no-padding ion-no-margin">
            <IonCardHeader className="ion-no-padding ion-margin-start">
              <IonText color={"dark"}>
                <strong>Detail {t("product")}</strong>
              </IonText>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              {so.sodet.map((item: any, key: number) => (
                <IonCard style={{ marginTop: "5px" }} key={key}>
                  <IonRow className="ion-no-padding ion-no-margin">
                    <IonCol size="12" className="ion-no-padding ion-no-margin">
                      <IonItem className="ion-no-padding ion-no-margin">
                        <IonText
                          className="ion-no-padding ion-no-margin ion-text-nowrap"
                          color={"dark"}
                        >
                          <p style={{ paddingLeft: "5px" }}>
                            <strong>{item.item_name}</strong>
                          </p>
                          <p style={{ paddingLeft: "5px" }}>
                            {`${item.item_quantity} x ${Rupiah(Number(item.item_price))} `}
                          </p>
                        </IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size="7" className="ion-no-padding ion-no-margin">
                      <IonItem
                        className="ion-no-padding ion-no-margin"
                        lines="none"
                      >
                        <IonText
                          className="ion-no-padding ion-no-margin"
                          color={"dark"}
                          style={{
                            margin: "3px",
                          }}
                        >
                          <span>
                            {t("Subtotal")} :{" "}
                            <strong>{Rupiah(item.item_subtotal)}</strong>
                          </span>
                        </IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol
                      size="5"
                      className="ion-no-padding ion-no-margin"
                    ></IonCol>
                  </IonRow>
                </IonCard>
              ))}
            </IonCardContent>
          </IonCard>
          <br />
          <IonCard className="ion-no-padding ion-no-margin">
            <IonCardHeader className="ion-no-padding ion-margin-start">
              <IonText color={"dark"}>
                <strong>{t("list") + " " + t("bill")}</strong>
              </IonText>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding ion-margin-start">
              <IonItem className="ion-no-padding ion-no-margin">
                <IonText color={"dark"} className="ion-text-left">
                  <p>Sub Total</p>
                  <p>Dasar Pengenaan Pajak</p>
                  <p>
                    {so.so_tax_rate ? `PPn ${so.so_tax_rate}%` : "Non Taxable"}
                  </p>
                </IonText>
                <IonText slot="end" className="ion-text-right">
                  <p>{Rupiah(so.so_subtotal_amount)}</p>
                  <p>{Rupiah(so.so_base_amount)}</p>
                  <p>{Rupiah(so.so_tax_rounded_amount)}</p>
                </IonText>
              </IonItem>
              <IonItem className="ion-no-padding ion-no-margin">
                <IonText color={"dark"} className="ion-text-left">
                  <strong>Total {t("bill")}</strong>
                </IonText>
                <IonText color={"dark"} slot="end">
                  <strong>{Rupiah(so.so_total_amount)}</strong>
                </IonText>
              </IonItem>
            </IonCardContent>
          </IonCard>
          <br />
          <IonCard className="ion-no-padding ion-no-margin">
            <IonCardContent className="ion-no-padding ion-margin-start">
              <IonItem className="ion-no-padding ion-no-margin" lines="none">
                <IonTextarea
                  labelPlacement="floating"
                  placeholder={t("add-note") ?? ""}
                  label={t("add-note") ?? ""}
                  name="note"
                  onIonInput={(e: any) =>
                    setSo((prevState: any) => ({
                      ...prevState,
                      so_remark: e.detail.value,
                    }))
                  }
                  value={so.so_remark}
                ></IonTextarea>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonContent>
      <IonFooter>
        <IonItem>
          <IonText slot="start" color={"light"}>
            Total {t("bill")} <br />
            <strong>
              {" "}
              {Rupiah(so.so_total_amount)} ({so.so_total_quantity})
            </strong>
          </IonText>
          <IonButton
            expand="block"
            color={"danger"}
            slot="end"
            className="ion-no-padding"
            style={{ width: "100%" }}
            onClick={createSo}
          >
            {`${t("confirm")}`}
          </IonButton>
        </IonItem>
      </IonFooter>
    </IonPage>
  );
};
export default Checkout;