import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  IonPage,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast
} from '@ionic/react';
import React, { useState} from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, arrowBackOutline, menuSharp, navigateCircleOutline, locateOutline, mapOutline, cashOutline, walletOutline ,documentOutline, cartOutline, storefrontSharp, checkmarkCircleOutline, closeCircleOutline} from "ionicons/icons";
import { GoogleMap, useLoadScript, Marker, Circle, InfoWindow } from '@react-google-maps/api';
import History from '../../components/visit/History';
import Ar from './Ar';
import BackOrder from './BackOrder';
import Payment from './Payment';
import Sales from './Sales';
import { RestApi } from '../../services/Api';
import { Rupiah, getRoleMenu } from '../../services/Library';
import Map from '../../components/Map';
import { Customer } from '../../models/Modal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Detail.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<Customer> = (props) =>{
  const [radius, setRadius] = useState<number>(50);
  const [customer, setCustomer] = useState<any>([]);
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [onLoadInfo, setOnLoadInfo] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "customer");
  const [presentToast] = useIonToast();

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
      getCustomerDetail(props.idCustomer)
      event.detail.complete();
  }

  const getCustomerDetail = (id:string) => {
    if(!id) {
      props.onDismissDetail()
      return;
    }
    setLoaded(false)
    RestApi(`customer/${id}`,'get',{},'application/json')
    .then(async(res:any) => {
        if (res.data.code === 200) {
          setCustomer(res.data.data)
          setRadius(Number(res.data.data.customer_location_radius??50))
          setLoaded(true)
        }else{
          props.onDismissDetail()
        }
    })
    .catch((err:any) => {
        props.onDismissDetail()
        massageAlert('error',err.message)
    });
  }
  
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP||''
  })

  const updateRadius = () => {
    alert({
      header: 'Masukan Radius',
      cssClass: 'header-alert',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: async (data) => {
            await presentLoading({message:'Harap Tunggu'})
            await RestApi(`customer/update-radius/${customer.customer_id}`,'post',{
              "radius" : data.radius,
            }).then(async (res:any) => {
                setRadius(Number(data.radius))
                if (res.data.code === 200) {
                  presentToast({
                    message: res.data.message,
                    duration: 1500,
                    icon: checkmarkCircleOutline,
                    color: "success"
                  });
                  dismissLoading()
                }else{
                  try {
                    let messageObj = JSON.parse(res.data.message)
                    let message = '';
                    for (var key in messageObj) {
                      // skip loop if the property is from prototype
                      if (!messageObj.hasOwnProperty(key)) continue;
                      var obj = messageObj[key];
                      for (var prop in obj) {
                          // skip loop if the property is from prototype
                          if (!obj.hasOwnProperty(prop)) continue;
                          // your code
                          message += key + " = " + obj[prop]
                      }
                    }
                    presentToast({
                      message: message,
                      duration: 1500,
                      icon: closeCircleOutline,
                      color: "danger",
                    });
                  } catch (error) {
                    presentToast({
                      message: res.data.message,
                      duration: 1500,
                      icon: closeCircleOutline,
                      color: "danger",
                    });
                  }
                  dismissLoading()
                }    
              })
              .catch((error:any)=>{
              presentToast({
                message: error.message,
                duration: 1500,
                icon: closeCircleOutline,
                color: "danger",
              });
              dismissLoading()
            })
          },
        },
      ],
      inputs: [
        {
          placeholder: 'Meter',
          type: 'number',
          value: radius,
          name: 'radius'
        }
      ],
    })
  }

  const handleDismissDetail = (e:any) => {
    getCustomerDetail(props.idCustomer)
    dismissMap()
  }

  const handleDismissHistory = (e:any) => {
    dismissHistory()
  }

  const handleDismissAr = (e:any) => {
    dismissAr()
  }

  const handleDismissBackOrder = (e:any) => {
    dismissBackOrder()
  }

  const handleDismissPayment = (e:any) => {
    dismissPayment()
  }

  const handleDismissSales = (e:any) => {
    dismissSales()
  }
  
  const [presentMap, dismissMap] = useIonModal(Map, {
    customer: customer,
    type: "customer",
      visit: null,
      onDismissDetail: handleDismissDetail
  });

  const [presentHistory, dismissHistory] = useIonModal(History, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissHistory
  });

  const [presentAr, dismissAr] = useIonModal(Ar, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissAr
  });

  const [presentBackOrder, dismissBackOrder] = useIonModal(BackOrder, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissBackOrder
  });
  
  const [presentPayment, dismissPayment] = useIonModal(Payment, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissPayment
  });

  const [presentSales, dismissSales] = useIonModal(Sales, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissSales
  });

  useConstructor(() => {
    getCustomerDetail(props.idCustomer)
  });
    
  return (
    <IonPage className="detail-content">
      <IonHeader>
        {loaded ? (
          <IonToolbar class="toolbar-detail-customer ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismissDetail()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonItem className="ion-no-padding" lines="none">
              <IonLabel className="ion-no-padding" color="light">
                <h4>
                  <strong>{customer.customer_name}</strong> (
                  {customer.customer_title})
                </h4>
                <span>Limit : {Rupiah(customer.customer_limit_credit)}</span>
              </IonLabel>
              <IonBadge
                slot="end"
                color={customer.customer_is_active ? "success" : "danger"}
              >
                {customer.customer_is_active ? "Aktif" : "InAktif"}
              </IonBadge>
            </IonItem>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
        {customer && loaded ? (
          <IonCard className="ion-no-padding">
            <IonItem>
              <IonText className="ion-no-padding">
                <span className="type">
                  {" "}
                  <strong>{customer.customer_type_name}</strong>
                </span>
              </IonText>
              <IonBadge slot="end" color="dark">
                {customer.area_name} - {customer.subarea_name}
              </IonBadge>
            </IonItem>
            <IonItem>
              <IonText className="ion-no-padding">
                <span>
                  Nama : <strong>{customer.customer_contact_name}</strong>
                </span>
                <span>
                  Jabatan : <strong>{customer.customer_contact_title}</strong>
                </span>
                <span>
                  Handphone : <strong>{customer.customer_phone}</strong>
                </span>
                <span>
                  Kota : <strong>{customer.customer_city}</strong>
                </span>
                <span>
                  Provinsi : <strong>{customer.customer_state}</strong>
                </span>
                <span>
                  Alamat : <strong>{customer.customer_address1}</strong>
                </span>
                <span>
                  Alamat Kirim:{" "}
                  <strong>{customer.customer_ship_address1}</strong>
                </span>
              </IonText>
            </IonItem>
            <IonCard className="ion-no-padding">
              <IonItem>
                <IonText className="ion-text-center">
                  <IonIcon
                    size="small"
                    className="ion-no-padding"
                    src={navigateCircleOutline}
                  />{" "}
                  Radius {radius} Meter
                </IonText>
              </IonItem>
              {isLoaded ? (
                <GoogleMap
                  mapContainerClassName="google-map"
                  center={{
                    lat: Number(
                      customer.customer_lat ?? user.branchs.branch_lat
                    ),
                    lng: Number(
                      customer.customer_lng ?? user.branchs.branch_lng
                    ),
                  }}
                  zoom={17}
                  options={{
                    gestureHandling: "none",
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                    clickableIcons: false,
                    keyboardShortcuts: false,
                  }}
                >
                  <Marker
                    icon={{
                      path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                      fillColor: "yellow",
                      fillOpacity: 0.9,
                      scale: 2,
                      strokeColor: "gold",
                      strokeWeight: 2,
                    }}
                    position={{
                      lat: Number(
                        customer.customer_lat ?? user.branchs.branch_lat
                      ),
                      lng: Number(
                        customer.customer_lng ?? user.branchs.branch_lng
                      ),
                    }}
                    onClick={() => setOnLoadInfo(!onLoadInfo)}
                  />
                  <Circle
                    center={{
                      lat: Number(
                        customer.customer_lat ?? user.branchs.branch_lat
                      ),
                      lng: Number(
                        customer.customer_lng ?? user.branchs.branch_lng
                      ),
                    }}
                    // required
                    options={{
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: "#FF0000",
                      fillOpacity: 0.35,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      visible: true,
                      radius: radius,
                      zIndex: 1,
                    }}
                  />

                  {onLoadInfo && (
                    <InfoWindow
                      onCloseClick={() => setOnLoadInfo(!onLoadInfo)}
                      position={{
                        lat: Number(
                          customer.customer_lat ?? user.branchs.branch_lat
                        ),
                        lng: Number(
                          customer.customer_lng ?? user.branchs.branch_lng
                        ),
                      }}
                    >
                      <IonText className="ion-no-padding">
                        <h4>
                          <IonIcon
                            size="small"
                            className="ion-no-padding"
                            src={storefrontSharp}
                          />
                          <strong>{customer.customer_name}</strong>
                        </h4>
                        <p>
                          <strong>{customer.customer_address1}</strong>
                        </p>
                      </IonText>
                    </InfoWindow>
                  )}
                </GoogleMap>
              ) : (
                <></>
              )}
            </IonCard>
          </IonCard>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            {getRole?.role_menu_edit && (
              <IonFabButton data-desc="Set Radius" onClick={updateRadius}>
                <IonIcon icon={navigateCircleOutline} />
              </IonFabButton>
            )}
            <IonFabButton
              data-desc="Lokasi"
              onClick={() => {
                presentMap();
              }}
            >
              <IonIcon icon={locateOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="History Visit"
              onClick={() => {
                presentHistory();
              }}
            >
              <IonIcon icon={mapOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Daftar Piutang"
              onClick={() => {
                presentAr();
              }}
            >
              <IonIcon icon={documentOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Back Order"
              onClick={() => {
                presentBackOrder();
              }}
            >
              <IonIcon icon={cartOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Pembayaran"
              onClick={() => {
                presentPayment();
              }}
            >
              <IonIcon icon={cashOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Penjualan"
              onClick={() => {
                presentSales();
              }}
            >
              <IonIcon icon={walletOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

export default Detail;
