import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const cartReducer = createSlice({
  name: "Cart",
  initialState: {
    value: [],
  },
  reducers: {
    addItem: (state: any, action: PayloadAction<any>) => {
      const index = state.value.findIndex(
        (item: any) => item.item_id === action.payload.item_id
      );
      if (index >= 0) {
        state.value[index] = action.payload;
      } else {
        state.value.push(action.payload);
      }
      return state;
    },
    incrementQuantity: (state: any, action: PayloadAction<any>) => {
      const item = state.value.find(
        (item: any) => item.item_id === action.payload
      );
      Number(item.item_quantity++);
    },
    decrementQuantity: (state: any, action: PayloadAction<any>) => {
      const item = state.value.find(
        (item: any) => item.item_id === action.payload
      );
      if (item.item_quantity === 1) {
        const removeItem = state.value.filter(
          (item: any) => item.item_id !== action.payload
        );
        state.value = removeItem;
      } else {
        Number(item.item_quantity--);
      }
      return state;
    },
    changeQuantity: (state: any, action: PayloadAction<any>) => {
      const item = state.value.find(
        (item: any) => item.item_id === action.payload.item_id
      );
      item.item_quantity = Number(action.payload.quantity);
      return state;
    },
    changeItem: (state: any, action: PayloadAction<any>) => {
      const index = state.value.findIndex(
        (item: any) => item.item_id === action.payload.item_id
      );
      if (index >= 0) {
        state.value[index] = action.payload;
      }
      return state;
    },
    changeCart: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        value: action.payload, // Assuming action.payload contains the updated cart data
      };
    },
    removeItem: (state: any, action: PayloadAction<any>) => {
      const removeItem = state.value.filter(
        (item: any) => item.item_id !== action.payload
      );
      state.value = removeItem;
      return state;
    },
    clearItem: (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.value = [];
      }
      return state;
    },
  },
});

export const CartReducer = cartReducer.reducer;
export const CartAction = cartReducer.actions;
