import {
    IonPage,
    IonContent,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonBadge,
    IonFooter,
} from '@ionic/react';
import React, { useState} from 'react';
import { getNameDate, Rupiah } from '../../services/Library';
import { RestApi } from '../../services/Api';
import { arrowBackOutline, cashOutline, walletOutline } from 'ionicons/icons';
import { Customer } from '../../models/Modal'
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';
import './Ar.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Ar: React.FC<Customer> = (props) =>{
    const { t } = useTranslation(["general"]);
    const [alert] = useIonAlert();
    const [listAr, setListAr] = useState<any>([])
    const [loaded, setLoaded] = useState<boolean>(false);
    const massageAlert = async (status:string,body:string) => {
        alert({
            header: t(status),
            message: body,
            cssClass: `header-alert-${status}`,
            buttons: [{ text: "Ya", cssClass: "alert-button-confirm", role: "confirm" }]
        })
    }

    const getArList = async (page:string) => {
        await RestApi(`${page}`,'get',{},'application/json')
        .then((res:any) => {
            if (res.data.code === 200) {
                setListAr(res.data.data)
                setLoaded(true)
            }else{
                massageAlert('error',res.data.message)
                props.onDismissDetail()
            }
        })
        .catch((err:any) => {
            props.onDismissDetail()
            massageAlert('error',err.message)
        });
    }

    const getOverdueDays = (date: string): string => {
      // Get the current date
      const lastDate = new Date(date);
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const timeDiff = currentDate.getTime() - lastDate.getTime();

      // Convert milliseconds to days
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const days = Math.floor(timeDiff / millisecondsPerDay);

      return ` (${days})`;
    };

    useConstructor(() => {
        getArList(`si/account-receivable/${props.idCustomer}`)
    });

    return (
      <IonPage>
        <IonHeader className="toolbar-ar">
          {loaded ? (
            <IonToolbar className="ion-no-padding">
              <IonButton
                color="light"
                slot="start"
                fill="clear"
                onClick={() => props.onDismissDetail()}
              >
                <IonIcon color="light" slot="start" src={arrowBackOutline} />
              </IonButton>
              <IonLabel
                color="light"
                className="ion-text-center ion-no-padding"
              >
                <h1>Daftar Piutang</h1>
                <h4>
                  <strong>
                    {listAr[0].si_customer_name} ({listAr[0].si_customer_id})
                  </strong>
                </h4>
              </IonLabel>
              <IonLabel slot="end" className="ion-padding">
                <IonBadge color="light" slot="end">
                  {listAr.length}
                </IonBadge>
              </IonLabel>
            </IonToolbar>
          ) : (
            <IonToolbar>
              <IonSkeletonText
                animated={true}
                style={{ width: "100%" }}
              ></IonSkeletonText>
              <IonSkeletonText
                animated={true}
                style={{ width: "100%" }}
              ></IonSkeletonText>
            </IonToolbar>
          )}
        </IonHeader>
        <IonContent className="content-ar">
          {!loaded && (
            <IonList>
              <IonListHeader>
                <IonSkeletonText
                  animated={true}
                  style={{ width: "80px" }}
                ></IonSkeletonText>
              </IonListHeader>
              {loadList.map((value: any, key: any) => (
                <IonItem key={key}>
                  <IonThumbnail slot="start">
                    <IonSkeletonText animated={true}></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "80%" }}
                      ></IonSkeletonText>
                    </h3>
                    <p>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "60%" }}
                      ></IonSkeletonText>
                    </p>
                    <p>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: "30%" }}
                      ></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}
          {listAr.length && loaded ? (
            <IonList className="main-card">
              {listAr.map((value: any, key: any) => (
                <IonCard className="ion-padding" key={key}>
                  <IonItem className="ion-no-padding">
                    <IonLabel className="ion-no-padding">
                      <span>{getNameDate(value.si_date)}</span>
                      <h4>
                        <strong>{value.si_id}</strong>
                      </h4>
                    </IonLabel>
                    <IonLabel slot="end" className="ion-no-padding">
                      <p>
                        <IonBadge color="danger">
                          <span>JT : {value.si_due_date}</span>
                        </IonBadge>{" "}
                        <IonBadge color="danger">
                          <span>
                            TOP : {value.si_term_days}
                            {getOverdueDays(value.si_due_date)}
                          </span>
                        </IonBadge>
                      </p>
                      <span>
                        <IonBadge color="dark" className="ion-float-end">
                          <span>{value.si_salesman_name}</span>
                        </IonBadge>
                      </span>
                    </IonLabel>
                  </IonItem>
                  <IonCardContent className="ion-no-padding">
                    <IonItem className="ion-no-padding">
                      <IonText slot="start">
                        <p>
                          Payment
                          <IonIcon color="primary" icon={cashOutline} />
                        </p>
                        <p>{Rupiah(value.si_total_payment)}</p>
                      </IonText>
                      <IonText slot="end">
                        <p>
                          Amount
                          <IonIcon color="primary" icon={walletOutline} />
                        </p>
                        <p>{Rupiah(value.si_total_amount)}</p>
                      </IonText>
                    </IonItem>
                    <IonItem className="ion-no-padding" lines="none">
                      <IonText slot="start">
                        <p>
                          Allocate
                          <IonIcon color="primary" icon={cashOutline} />
                        </p>
                        <p>{Rupiah(value.si_total_allocate)}</p>
                      </IonText>
                      <IonText slot="end">
                        <p>
                          Owing
                          <IonIcon color="primary" icon={walletOutline} />
                        </p>
                        <p>{Rupiah(value.si_total_owing)}</p>
                      </IonText>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              ))}
            </IonList>
          ) : (
            <IonImg
              className="img-empty"
              src={"assets/img/search-empty.webp"}
            />
          )}
        </IonContent>
        <IonFooter className="toolbar-ar">
          <IonToolbar>
            <IonItem className="ion-no-padding">
              <IonText color="light" className="ion-padding-start" slot="start">
                <p>
                  <strong>Total Payment </strong>
                  <IonIcon color="primary" icon={cashOutline} /> <br />
                  {Rupiah(
                    listAr.reduce(
                      (a: any, b: any) => +a + +b.si_total_allocate,
                      0
                    )
                  )}
                </p>
              </IonText>
              <IonText color="light" className="ion-padding-end" slot="end">
                <p>
                  {" "}
                  <strong>Total Amount </strong>
                  <IonIcon color="primary" icon={walletOutline} /> <br />
                  {Rupiah(
                    listAr.reduce((a: any, b: any) => +a + +b.si_total_owing, 0)
                  )}
                </p>
              </IonText>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonText color="light" className="ion-padding-start" slot="start">
                <p>
                  <strong>Total Allocate </strong>
                  <IonIcon color="primary" icon={cashOutline} /> <br />
                  {Rupiah(
                    listAr.reduce(
                      (a: any, b: any) => +a + +b.si_total_allocate,
                      0
                    )
                  )}
                </p>
              </IonText>
              <IonText color="light" className="ion-padding-end" slot="end">
                <p>
                  {" "}
                  <strong>Total Owing </strong>
                  <IonIcon color="primary" icon={walletOutline} /> <br />
                  {Rupiah(
                    listAr.reduce((a: any, b: any) => +a + +b.si_total_owing, 0)
                  )}
                </p>
              </IonText>
            </IonItem>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
};

export default Ar;
