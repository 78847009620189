import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Menu from './components/Menu';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Customer from './pages/Customer';
import Bill from './pages/Bill';
import Visit from './pages/Visit';
import CustomerProfile from './pages/CustomerProfile';
import { Plugins, Capacitor, registerPlugin } from "@capacitor/core";
import { Provider } from "react-redux";
import { Store } from "./data/state/Store";
import { useAuth } from "./data/sessions/Auth";
import { StatusBar  } from '@capacitor/status-bar';
import CheckPermissions from './services/Permission';
import { BackgroundGeolocationPlugin } from '@capacitor-community/background-geolocation';
import { SplashScreen } from "@capacitor/splash-screen";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import "./App.scss";
import So from './pages/So';

setupIonicReact({ mode: "md" , hardwareBackButton: true});

export const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");

const listRoute = [
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/customer",
    component: Customer,
  },
  {
    path: "/customer_profile",
    component: CustomerProfile,
  },
  {
    path: "/bill",
    component: Bill,
  },
  {
    path: "/visit",
    component: Visit,
  },
  {
    path: "/so",
    component: So,
  },
];
const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const App: React.FC = () => {
  const { authInfo, initialize } = useAuth()!;
  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener(
        "backButton",
        (e: any) => {
          if (
            window.location.pathname === "/home" ||
            window.location.pathname === "/login"
          ) {
            Plugins.App.exitApp();
          } else {
            history.goBack();
          }
        },
        { capture: true }
      );
    }
    !authInfo?.initialized && (async () => await initialize())();
  }, [authInfo, initialize, history]);

  useConstructor(async () => {
    if (Capacitor.isNative) {
      CheckPermissions.requestFineLocation();
      CheckPermissions.requestCameraPermission();
      CheckPermissions.requestFilesystem();
      await StatusBar.show();
      await SplashScreen.hide();
    }
  });

  if (!authInfo || !authInfo.initialized) {
    return (
      <Provider store={Store}>
        <IonApp>
          <IonLoading isOpen={true} />
        </IonApp>
      </Provider>
    );
  } else {
    return (
      <Provider store={Store}>
        <IonApp>
          <>
            {authInfo?.loggedIn ? (
              <IonReactRouter>
                <Menu />
                <IonSplitPane contentId="main">
                  <IonRouterOutlet id="main">
                    <Switch>
                      {listRoute.map((val, key) => (
                        <Route
                          key={key}
                          path={val.path}
                          component={val.component}
                          exact
                        />
                      ))}
                      <Redirect from="/" to="/home" exact />
                    </Switch>
                  </IonRouterOutlet>
                </IonSplitPane>
              </IonReactRouter>
            ) : (
              <IonReactRouter>
                <Route path="/login" component={Login} exact />
                <Redirect from="/" to="/login" exact />
              </IonReactRouter>
            )}
          </>
        </IonApp>
      </Provider>
    );
  }
};

export default App;
