export interface AppPage {
  url: string;
  icon: string;
  title: string;
}

export interface Target1 {
  description: string
  target: string
  sales: number
  ach: number
}

export interface Target2 {
  name: string;
  type: string;
  budget: number;
  quantity: number;
  amount: number;
  sales: number;
  target: number;
  total: number;
}

export interface Achievement {
  paymentSystem: string
  duedate: number
  allowance: number
  duedateAndAllowace: number
  totalAllowance: number
}

export const loadList: any = [
  {
    i: 0,
  },
  {
    i: 1,
  },
  {
    i: 2,
  },
  {
    i: 3,
  },
  {
    i: 4,
  },
  {
    i: 5,
  },
  {
    i: 6,
  },
  {
    i: 7,
  },
  {
    i: 8,
  },
  {
    i: 9,
  },
];

export interface Item {
  text: string;
  value: string;
}

export interface SelectOptionProps {
  items: Item[];
  selectedItems: string[];
  title?: string;
  multiple: boolean;
  onSelectionCancel: () => void;
  onSelectionChange: (items: string[]) => void;
  isOpen: boolean;
}

export interface DateProps {
  onCloseModal: any;
  initialData: any;
  isOpen: boolean;
}

export interface Discount {
  [key: string]: number;
}
