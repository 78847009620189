import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCard,
  IonRow,
  IonCol,
  IonContent,
  IonItem,
  IonInput,
  IonIcon,
  IonText,
  useIonAlert,
  useIonLoading
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import { OverlayEventDetail } from '@ionic/core/components';
import { arrowBackOutline, keyOutline } from "ionicons/icons";
import { RestApi } from '../../services/Api';
import { useAuth } from "../../data/sessions/Auth";
import { useTranslation } from 'react-i18next';
import './ChangePassword.scss';

const ChangePassword: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { logOut } = useAuth()!;
  const [oldPassword, setOldPassword] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirm, setPasswordConfirm] = useState<string>();

  const massageAlert = async (status:string,body:string) => {
     alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{cssClass: 'alert-button-confirm', text: 'Ok' }],
    })
  }

  const changePassword = async () => {
    if (!oldPassword || !password || !passwordConfirm) {
        massageAlert('error','Please enter a valid input')
        return;
    }
    await presentLoading({message:'Harap Tunggu'})
    await RestApi('user/changepassword','post',{
      "old_password" : oldPassword,
      "password" : password,
      "password_confirmation" : passwordConfirm
    }).then(async (res:any) => {
        if (res.data.code === 200) {
          massageAlert("success", res.data.message); 
          modal.current?.dismiss()
          await logOut();
          dismissLoading()
        }else{
          massageAlert('error',res.data.message)  
          dismissLoading()
        }    
    })
    .catch((error:any)=>{
        dismissLoading()
        massageAlert('error',error.message)
    })
  }

  const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
    modal.current?.dismiss()
  }

  return (
    <IonModal
      ref={modal}
      trigger="change-password-modal"
      onWillDismiss={(ev) => onWillDismiss(ev)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => modal.current?.dismiss()}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonText color="light" className="ion-text-center">
            <h4>Ubah Password</h4>
          </IonText>
          <IonButton slot="end" fill="clear"></IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonCard>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol
                class="ion-align-self-center"
                size-md="12"
                size-lg="12"
                size-xs="12"
              >
                <IonItem>
                  <IonIcon slot="start" icon={keyOutline} />
                  <IonInput
                    type="password"
                    labelPlacement="floating"
                    label="Password Lama"
                    placeholder=" Masukan Password Lama"
                    value={oldPassword}
                    required
                    onIonInput={(e: any) => setOldPassword(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonIcon slot="start" icon={keyOutline} />
                  <IonInput
                    type="password"
                    labelPlacement="floating"
                    label="Masukan Password Baru"
                    placeholder=" Masukan Password Baru"
                    value={password}
                    required
                    onIonInput={(e: any) => setPassword(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonIcon slot="start" icon={keyOutline} />
                  <IonInput
                    type="password"
                    labelPlacement="floating"
                    label="Ulangi Password"
                    placeholder=" Masukan Ulangi Password"
                    value={passwordConfirm}
                    required
                    onIonInput={(e: any) =>
                      setPasswordConfirm(e.detail.value!)
                    }
                  />
                </IonItem>
                <IonButton
                  expand="block"
                  type="submit"
                  onClick={changePassword}
                >
                  Change
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default ChangePassword;
