import React from "react";
import { Preferences } from '@capacitor/preferences'
import { ClearPresistor } from "../../data/state/Store";

type UserDataInterface = { initialized: boolean; loggedIn: boolean; user: any };
type MyContextInterface = {
  authInfo: UserDataInterface;
  initialize: () => Promise<boolean>;
  logOut: () => Promise<boolean>;
  logIn: () => Promise<boolean>;
};

// create the context
export const AuthContext = React.createContext<MyContextInterface | undefined>(
  undefined
);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...

export const AuthProvider: React.FC = (props: any) => {
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();

  const logOut = () => {
    return new Promise(async (resolve) => {
      const {value} =  await Preferences.get({key: "url" });
      window.localStorage.setItem("port", value?.replace(/\D/g, "") ?? "");
      window.localStorage.removeItem("_capuid");
      await Preferences.clear()
      ClearPresistor()
      setAuthInfo({ initialized: true, loggedIn: false, user: null });
      setTimeout(() => {
        return resolve(true);
      }, 1000);
    });
  };

  const logIn = () => {
    return new Promise(async (resolve) => {
      let v = {
        initialized: true,
        loggedIn: true,
        user: { id: new Date().getTime() + "" },
      };
      setAuthInfo(v);
      await Preferences.set({key: "auth", value: JSON.stringify(v.user) });
      setTimeout(() => {
        return resolve(true);
      }, 1000);
    });
  };

  const initialize = async() => {
    let data = await Preferences.get({key: "auth" });
    if (data.value !== null) {
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        user: JSON.parse(data.value),
      });
    } else {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: null,
      });
    }
  };

  let v = {
    authInfo,
    logOut: logOut,
    logIn: logIn,
    initialize,
  };

  return <AuthContext.Provider value={v} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);
