import {
  IonContent,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonIcon,
  IonAvatar, 
  IonLabel,
  IonImg,
  IonText,
  IonBadge
} from '@ionic/react';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Preferences } from '@capacitor/preferences';
import { useAuth } from "./../data/sessions/Auth";
import Tabel from './../components/home/Tabel';
import { useTranslation } from 'react-i18next';
import './Home.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Home: React.FC = () => {
  const { logOut } = useAuth()!;
  const [baseUrl, setBaseUrl] = useState<any>();
  const user = useSelector((state:any) => state.user.value);
  const history = useHistory();
  const { t } = useTranslation(["general"]);

  const getGreetingTime = () => {
    let today = new Date();
    let curHr = today.getHours();
    if (curHr < 12) {
      return t("good-morning");
    } else if (curHr < 15) {
      return t("good-afternoon");
    } else if (curHr < 18) {
      return t("good-evening");
    } else {
      return t("good-night");
    }
  };
  
  useConstructor(async () => {
    try {
      if (typeof user == "undefined" || !user){
        logOut()
      }
      const url =  await Preferences.get({key: "url" });
      setBaseUrl(url.value)
      
    } catch (error) {
      logOut();
    }
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonAvatar slot="start" onClick={(e) => {
              e.preventDefault();
              history.push('/profile');
            }}>
            <IonImg src={baseUrl+'/image/profile/'+user.user_avatar} />
          </IonAvatar>
          <IonLabel color="light">
            <span><strong>{getGreetingTime()}</strong> <br />{user.user_name.toUpperCase()}</span></IonLabel>
          <IonText slot='end' className='ion-padding'>
            <IonBadge color='dark'>
              {user.company_id}
            </IonBadge>
          </IonText>
          <IonButtons slot="end">
            <IonMenuButton>
              <IonIcon color="light" icon={ellipsisVerticalOutline} />
            </IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Tabel/>
        {/* <Chart/> */}
      </IonContent>
    </IonPage>
  );
};

export default Home;
