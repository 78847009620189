import axios from "axios";
import { Preferences } from '@capacitor/preferences'

export const RestApi = async (path:string,method:string,data:any,content:string = 'application/json') => {
    const baseURL = await Preferences.get({key: "url" });
    const token = await Preferences.get({key: "token" });
    let param = '?';
    if (path.includes("?")) {
      param = "&";
    }
    return axios({
      method: method,
      url: `${baseURL.value}/api/${path}${param}version=${process.env.REACT_APP_VERSION}`,
      data: data,
      headers: {
        "Content-Type": content,
        Authorization: `bearer ${token.value}`,
      },
    });
};
