import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationIndonesia from "./translation/id/translation.json";
import translationEnglish from "./translation/en/translation.json";

//---Using different namespaces
const resources = {
  id: {
    general: translationIndonesia,
  },
  en: {
    general: translationEnglish,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "id", //default language
});

export default i18next;
