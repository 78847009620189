import {
    IonContent,
    IonPage,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonText,
    IonCard,
    IonItem,
    IonList,
    IonBadge,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonInput,
    IonInfiniteScroll, 
    IonInfiniteScrollContent,
    useIonModal,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
    useIonToast
} from '@ionic/react';
import React, { useState } from 'react';
import { storefrontOutline, searchOutline, arrowBackOutline , personCircleOutline, timeOutline, chevronDownCircleOutline, addCircleOutline, warningOutline } from 'ionicons/icons';
import { RestApi } from '../services/Api';
import Detail from '../components/customer-profile/Detail';
import Form from '../components/customer-profile/Form';
import { loadList } from '../models/Data';
import { useTranslation } from 'react-i18next';
import { getNameDate, getRoleMenu } from '../services/Library';
import './CustomerProfile.scss';
import { useSelector } from 'react-redux';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const CustomerProfile: React.FC = () => {
  const [presentToast] = useIonToast();
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "customer_profile");
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [getIdCustomerProfile, setGetIdCustomerProfile] = useState<Number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [listCustomerProfile, setListCustomerProfile] = useState<any>([]);
  const [dataCustomerProfile, setDataCustomerProfile] = useState<any>([]);
  const status: any = [
    {
      name: t("cancel"),
      color: "danger",
    },
    {
      name: t("check"),
      color: "secondary",
    },
    {
      name: t("wait-approve"),
      color: "warning",
    },
    {
      name: t("approve"),
      color: "success",
    },
  ];

  const handleDismissDetail = () => {
    dismissDetail();
    getCustomerProfileList(
      `customer-profile?page=1&search=${searchText}`,
      false
    );
  };

  const handleDismissForm = () => {
    dismissForm();
    getCustomerProfileList(
      `customer-profile?page=1&search=${searchText}`,
      false
    );
  };

  const [presentDetail, dismissDetail] = useIonModal(Detail, {
    idCustomerProfile: getIdCustomerProfile,
    type: null,
    onDismiss: handleDismissDetail,
  });

  const [presentForm, dismissForm] = useIonModal(Form, {
    idCustomerProfile: getIdCustomerProfile,
    type: "create",
    onDismiss: handleDismissForm,
  });

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getCustomerProfileList(
      `customer-profile?page=1&search=${searchText}`,
      false
    );
    event.detail.complete();
  };

  const getCustomerProfileList = async (page: string, next: boolean) => {
    await RestApi(page, "get", {}, "application/json")
      .then((res: any) => {
        if (res.data.code === 200) {
          setDataCustomerProfile(res.data.data);
          if (next) {
            setListCustomerProfile((prevState: any) => [
              ...prevState,
              ...res.data.data.data,
            ]);
          } else {
            setListCustomerProfile(res.data.data.data);
          }
        } else {
          setListCustomerProfile([])
          setDataCustomerProfile([])
          presentToast({
            message: res.data.message,
            duration: 2500,
            color: "danger",
            icon: warningOutline,
          });          
        }
        setLoaded(true);
      })
      .catch((err: any) => {
        massageAlert('error', err.message);
        setLoaded(true);
      });
  };

  const loadData = (ev: any) => {
    if (dataCustomerProfile.current_page === dataCustomerProfile.last_page) {
      setInfiniteDisabled(true);
      return;
    }
    getCustomerProfileList(`customer-profile?page=${dataCustomerProfile.current_page + 1}&search=${searchText}`,true);
    ev.target.complete();
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.detail.value!);
    getCustomerProfileList(`customer-profile?page=1&search=${e.detail.value!}`, false);
  };

  useConstructor(() => {
    getCustomerProfileList(`customer-profile?page=1&search=${searchText}`, false);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="toolbar-customer-profile">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            routerLink="/home"
            routerDirection="back"
            className=""
            size="small"
          >
            <IonIcon color="light" src={arrowBackOutline} />
          </IonButton>
          <div className="search" slot="end">
            <IonItem lines="none" className="item-customer">
              <IonInput
                placeholder={t("search") + " " + t("module.customer-profile")}
                className="input-customer"
                onIonInput={(e) => onChangeSearch(e)}
              ></IonInput>
              <IonIcon src={searchOutline} slot="start" />
              <IonBadge color="medium">{dataCustomerProfile.total}</IonBadge>
            </IonItem>
          </div>
          {getRole?.role_menu_add === 1 && (
            <IonButton
              fill="clear"
              slot="end"
              color="light"
              className=""
              onClick={() => {
                presentForm();
              }}
            >
              <IonIcon
                size="small"
                className="ion-no-margin ion-no-padding"
                src={addCircleOutline}
              ></IonIcon>
              {t("create")}
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="customer-profile-content">
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t("loading") ?? ""}
            refreshingSpinner="circles"
            refreshingText={t("loading") ?? ""}
          ></IonRefresherContent>
        </IonRefresher>
        {listCustomerProfile.length && loaded ? (
          <IonList className="main-card">
            {listCustomerProfile.map((value: any, key: any) => (
              <IonCard
                className="ion-padding-top"
                key={key}
                onClick={() => {
                  setGetIdCustomerProfile(value.customer_profile_id);
                  presentDetail();
                }}
              >
                <IonItem>
                  <IonText className="ion-no-padding ion-no-margin">
                    <p className="ion-no-padding ion-no-margin">
                      <span className="ion-no-padding ion-no-margin">
                        <IonIcon
                          size="small"
                          style={{
                            marginRight: "2px",
                          }}
                          src={storefrontOutline}
                        />
                        {value.customer_profile_name + " "}
                        <IonBadge color="dark">
                          {value.customer_profile_customer_id}
                        </IonBadge>
                      </span>{" "}
                      <br />
                      <span className="ion-no-padding ion-no-margin">
                        <IonIcon
                          size="small"
                          className="ion-no-padding"
                          src={personCircleOutline}
                        />
                        {value.customer_profile_create_by}
                      </span>
                    </p>
                  </IonText>
                  <IonLabel className="ion-no-padding ion-no-margin" slot="end">
                    <p className="ion-no-padding ion-no-margin">
                      <span className="ion-no-padding ion-no-margin">
                        <IonIcon
                          size="small"
                          style={{
                            marginRight: "2px",
                          }}
                          src={timeOutline}
                        />
                        {getNameDate(value.customer_profile_create_at)}
                      </span>
                      <br />
                      <span className="ion-no-padding ion-no-margin">
                        <IonBadge
                          className="ion-float-end"
                          color={status[value.customer_profile_status].color}
                        >
                          {status[value.customer_profile_status].name}
                        </IonBadge>
                      </span>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonCardContent className="ion-no-padding">
                  <IonItem lines="none">
                    <IonText className="ion-no-padding ion-no-margin">
                      <p>{value.customer_profile_address1}</p>
                    </IonText>
                    <IonText slot="end" className="ion-no-padding">
                      <p>{value.customer_profile_phone}</p>
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
          </IonList>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          {dataCustomerProfile.next_page_url && (
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonInfiniteScrollContent>
          )}
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default CustomerProfile;
