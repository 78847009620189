import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonToolbar,
  IonText,
  IonModal,
  CheckboxCustomEvent,
  IonInput,
  IonIcon,
} from "@ionic/react";
import { Item, SelectOptionProps } from "../../models/Data";
import { OverlayEventDetail } from "@ionic/core";
import { useTranslation } from "react-i18next";
import { searchOutline } from "ionicons/icons";
import "./SelectOption.scss";

const SelectOption: React.FC<any> = (props: SelectOptionProps) => {
  const modalInputSelectOption = useRef<HTMLIonModalElement>(null);
  const [filteredItems, setFilteredItems] = useState<Item[]>([...props.items]);
  const { t } = useTranslation(["general"]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState<string[]>(
    props.selectedItems
  );

  const isChecked = (value: any) => {
    return workingSelectedValues.find((item) => item === value) !== undefined;
  };

  const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
    props.onSelectionCancel();
    modalInputSelectOption.current?.dismiss();
  };

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        props.items.filter((item) => {
          return item.text.toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail;
    if (checked) {
      if (props.multiple) {
        setWorkingSelectedValues([...workingSelectedValues, value]);
      } else {
        setWorkingSelectedValues([value]);
        props.onSelectionChange(value);
        modalInputSelectOption.current?.dismiss();
      }
    } else {
      setWorkingSelectedValues(
        workingSelectedValues.filter((item) => item !== value)
      );
    }
  };

  useEffect(() => {}, [props.isOpen]);

  return (
    <IonModal
      ref={modalInputSelectOption}
      isOpen={props.isOpen ?? false}
      initialBreakpoint={0.9}
      breakpoints={[0, 1.0]}
      handleBehavior="cycle"
      onWillDismiss={(ev) => onWillDismiss(ev)}
    >
      <IonHeader>
        <IonToolbar>
          <IonText color="light" slot="start" className="ion-margin-start">
            <h2> {props.title}</h2>
          </IonText>
          <IonButton
            color="light"
            slot="end"
            fill="clear"
            onClick={() => {
              props.onSelectionChange(workingSelectedValues);
              modalInputSelectOption.current?.dismiss();
            }}
          >
            {t("submit")}
          </IonButton>
        </IonToolbar>
        <IonToolbar>
          <div className="search">
            <IonItem lines="none" className="item-select">
              <IonInput
                placeholder={`${t("search") ?? ""} ${props.title}`}
                className="input-select"
                onIonInput={(e: any) => filterList(e.detail.value!)}
              ></IonInput>
              <IonIcon src={searchOutline} slot="start" />
            </IonItem>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" className="ion-no-padding ion-no-margin">
        <IonList
          id="modal-list"
          inset={true}
          className="ion-no-padding ion-no-margin"
        >
          {filteredItems.map((item, key) => (
            <IonItem key={key}>
              <IonCheckbox
                value={item.value}
                checked={isChecked(item.value)}
                onIonChange={checkboxChange}
              >
                {item.text}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};
export default SelectOption;
