import {
    IonContent,
    IonPage,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    useIonModal,
    IonRefresher,
    IonBadge,
    IonRefresherContent
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import React, { useState } from 'react';
import { storefront, arrowBackOutline, logIn, logOut, chatboxEllipses, chevronDownCircleOutline, calendar } from 'ionicons/icons';
import { RestApi } from './../services/Api';
import { getNameDate } from './../services/Library';
import Detail from './../components/visit/Detail';
import { useSelector, useDispatch } from "react-redux";
import { VisitAction } from "../data/state/VisitReducer";
import { CustomerAction } from "../data/state/CustomerReducer";
import { Preferences } from '@capacitor/preferences'
import Calendar from '../components/visit/Calendar';
import { loadList } from '../models/Data';
// import { Clipboard } from '@capacitor/clipboard';
// import { useLongPress } from 'use-long-press';
import { useTranslation } from 'react-i18next';
import './Visit.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Visit: React.FC = () => {
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const dispatch = useDispatch();
  const [getDetailVisit, setGetDetailVisit] = useState<any>([]);
  // const [copyText, setCopyText] = useState<any>([])
  const [loaded, setLoaded] = useState<boolean>(false);
  const listVisit = useSelector((state:any) => state.visit.value) || [];

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
      dispatch(CustomerAction.setCustomer([]))
      getVisitList()
      setGetDetailVisit([])
      event.detail.complete();
  }

  const handleDismissCalendar = () => {
    dispatch(CustomerAction.setCustomer([]));
    dismissCalendar();
  };

  const [presentCalendar, dismissCalendar] = useIonModal(Calendar, {
    onDismissCalendar: handleDismissCalendar,
  });

  const handleDismissDetail = () => {
      dispatch(CustomerAction.setCustomer([]))
      dismissDetail()
  }

  const [presentDetail, dismissDetail] = useIonModal(Detail, {
    detailVisit: getDetailVisit,
    onDismissDetail: handleDismissDetail,
    type: "visit",
  });

  const massageAlert = async (status:string,body:string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [
        { text: "Ya", cssClass: "alert-button-confirm", role: "confirm" },
      ],
    });
  }

  const getVisitList = async () => {
      setLoaded(false)
      Preferences.remove({key: "visitOn"});
      await RestApi(`visit`,'get',{},'application/json')
      .then((res:any) => {
          if (res.data.code === 200) {
              dispatch(VisitAction.setVisit(res.data.data))
          }else{
              dispatch(VisitAction.setVisit([]))
              massageAlert('error',res.data.message)
          }
          setLoaded(true)
      })
      .catch((err:any) => {
          massageAlert('error',err.message)
          setLoaded(true)
      });
  }

  const getVisitDetail = async (value:any) => {
      dispatch(CustomerAction.setCustomer(value))
      setGetDetailVisit(value)
      presentDetail()
  }
  
  const checkStatus = (status:any) =>{
      switch(status) {
          case 0:
              return {
                  color : 'danger',
                  icon : logIn
              };
          case 1:
              return {
                  color : 'success',
                  icon : logOut
              };
          default:
              return {
                  color : 'tertiary',
                  icon : storefront
              };
      }
  }

  // const onLongPress = async (value:string) => {
  //     setCopyText(value)
  //     await Clipboard.write({
  //         string: copyText
  //     });
  // };

  // const writeToClipboard = useLongPress((e:any) => {
  //     massageAlert('succes',`Teks ${copyText} dicopy ke clipboard`)
  // });

  const visitCheck = (value: any) => {
      if (value.visit_hist_type === 0 ) {
          if (getDetailVisit.customer_id === value.customer_id) {
              return
          }else{
              Preferences.set({key: "visitOn", value: JSON.stringify(value) });
              alert({
                  header: `Customer ${value.customer_id} Belum Di Proses !`,
                  message: `${value.visitdet_note === ''?'Isi Catatan':'Check Out'} Sekarang Atau nanti?`,
                  cssClass: 'header-alert',
                  buttons: [
                      {
                          text: 'Nanti',
                          cssClass: 'alert-button-cancel',
                          role: 'cancel',
                      },
                      {
                          text: 'Sekarang',
                          cssClass: 'alert-button-confirm',
                          role: 'confirm',
                          handler:  () => {
                              dispatch(CustomerAction.setCustomer(value))
                              setGetDetailVisit(value)
                              presentDetail()
                      },
                  }]
              })
          }
      }
  }

  useConstructor(() => {
    dispatch(CustomerAction.setCustomer([]))
    getVisitList()
    setGetDetailVisit([])
  });

  return (
    <IonPage>
      <IonHeader className="toolbar-visit">
        {loaded ? (
          <IonToolbar>
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              routerLink="/home"
              routerDirection="back"
              className=""
              size="small"
            >
              <IonIcon color="light" src={arrowBackOutline} />
            </IonButton>
            <IonLabel color="light" className="ion-text-center ion-no-padding">
              <h1>
                <strong>{listVisit[0]?.visit_id}</strong>
              </h1>
              <h4>{getNameDate(listVisit[0]?.visit_date)}</h4>
            </IonLabel>
            <IonButton
              color="light"
              onClick={() => {
                presentCalendar();
              }}
              slot="end"
              fill="clear"
            >
              <IonIcon color="light" src={calendar} />
            </IonButton>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="content-visit">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t("loading") ?? ""}
            refreshingSpinner="circles"
            refreshingText={t("loading") ?? ""}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        {listVisit.length && loaded ? (
          <>
            <IonText className="ion-text-center ion-no-padding">
              <h4>
                <strong>
                  Total Kunjungan
                  {listVisit.filter((e: any) => e.visit_hist_type === 1).length}
                  /{listVisit.length}
                </strong>
              </h4>
            </IonText>
            <IonList className="main-card">
              {listVisit.map((value: any, key: any) => (
                <IonCard className="ion-padding" key={key}>
                  <IonItem
                    className="ion-no-padding"
                    onClick={() => getVisitDetail(value)}
                  >
                    <IonText className="ion-no-padding">
                      <p>
                        <strong>
                          {key + 1}. {value.customer_name}
                        </strong>
                        <IonBadge color="dark">{value.customer_id} </IonBadge>
                      </p>
                    </IonText>
                    <IonIcon
                      slot="end"
                      size="medium"
                      color={checkStatus(value.visit_hist_type).color}
                      src={checkStatus(value.visit_hist_type).icon}
                    />
                    {value.visitdet_note && (
                      <IonIcon
                        slot="end"
                        size="medium"
                        color="danger"
                        src={chatboxEllipses}
                      />
                    )}
                  </IonItem>
                  <IonCardContent
                    className="ion-no-padding"
                    onClick={() => getVisitDetail(value)}
                  >
                    <IonItem className="ion-no-padding" lines="none">
                      <IonLabel className="ion-no-padding">
                        <p>{value.customer_address1}</p>
                      </IonLabel>
                      <IonText slot="end" className="ion-no-padding">
                        <p>{value.customer_phone}</p>
                      </IonText>
                    </IonItem>
                  </IonCardContent>
                  {visitCheck(value)}
                </IonCard>
              ))}
            </IonList>
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Visit;
