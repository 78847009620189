/* eslint-disable array-callback-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const visitReducer = createSlice({
  name: "Visit",
  initialState: {},
  reducers: {
    setVisit: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.value = action.payload;
    },
    updateVisit: (state: any, action: PayloadAction<any>) => {
      state.value.map((visit:any) => {
        if (visit.customer_id === action.payload.customer_id && visit.visit_id === action.payload.visit_id) {
          for ( var property in action.payload.update ) {
            visit[property] = action.payload.update[property]
          }
          return true
        }
      });

    },
  },
});

export const VisitReducer = visitReducer.reducer;
export const VisitAction = visitReducer.actions;