import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCard,
  IonRow,
  IonCol,
  IonContent,
  IonItem,
  IonImg,
  IonInput,
  IonIcon,
  IonText,
  useIonActionSheet,
  useIonAlert, 
  useIonLoading
} from '@ionic/react';
import React, { useState, useRef } from 'react';
import { CameraSource } from '@capacitor/camera';
import { OverlayEventDetail } from '@ionic/core/components';
import { arrowBackOutline, cameraOutline, imageOutline } from "ionicons/icons";
import { usePhotoGallery } from '../../services/Camera';
import { useSelector,useDispatch } from "react-redux";
import { UserAction } from "../../data/state/UserReducer";
import { RestApi } from '../../services/Api';
import { Preferences } from '@capacitor/preferences';
import { useAuth } from "../../data/sessions/Auth";
import { Capacitor, Plugins } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import './Update.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Index: React.FC = () => {
  const { logOut } = useAuth()!;
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(undefined);
  const dispatch = useDispatch();
  const [present] = useIonActionSheet();
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [presentLoading, dismissLoading] = useIonLoading();
  const {deletePhoto, photos, takePhoto } = usePhotoGallery();
  const user = useSelector((state:any) => state.user.value);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | undefined>(undefined);
  const [imageProfile, setImageProfile] = useState<any>();
  const [baseUrl, setBaseUrl] = useState<any>();
  const [name, setName] = useState<string>(user.user_name);
  const [email, setEmail] = useState<string>(user.user_email);
  const [login, setLogin] = useState<string>(user.user_login);
  
  const selectImageSource = async () => {
    const buttons = [
      {
        text: "Take Photo",
        icon: cameraOutline,
        handler: async () => {
          await takePhoto(CameraSource.Camera);
        },
      },
      {
        text: "Choose From Photos Photo",
        icon: imageOutline,
        handler: async () => {
          await takePhoto(CameraSource.Photos);
        },
      },
    ];

    await present({
      header: 'Select Image Source',
      buttons
    });
  }
  
  const massageAlert = async (status:string,body:string) => {
     alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{cssClass: 'alert-button-confirm', text: 'Ok' }],
    })
  }
  
  const updateProfile = async (e:any) => {
    e.preventDefault()
    await presentLoading({message:'Harap Tunggu'})
    const FormData = global.FormData;
    const formData = new FormData();
    if (photos.length) {
      formData.append("user_avatar", photos[0].blobData);
    }
    formData.append("user_name", name);
    formData.append("user_login", login);
    formData.append("user_email", email);
    await RestApi('user','post',formData,'application/x-www-form-urlencoded')
      .then((res:any) => {
        if (res.data.code === 200) {
          deletePhoto(photos[0])
          massageAlert('success',res.data.message)
          dispatch(UserAction.updateProfile({
            user_name : name,
            user_avatar : res.data.data.user_avatar
          }))
          setImageProfile(baseUrl + '/image/profile/' + user.user_avatar)
          dismissLoading()
          modal.current?.dismiss()
          if (user.user_login !== login) {
            logOut()
          }
        }else{
          massageAlert('error',res.data.message)  
          dismissLoading()
        }    
      })
      .catch((err:any) => {
        massageAlert('error',err.message)
        dismissLoading()
      });
  }

  const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
    if (photos.length) {
      deletePhoto(photos[0])
    }
    setImageProfile(baseUrl + '/image/profile/' + user.user_avatar)
    modal.current?.dismiss()
  }

  useConstructor(async () => {
      const url =  await Preferences.get({key: "url" });
      setBaseUrl(url.value)
      setPresentingElement(page.current);
      if (!user) {
        logOut()
      }
      if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e: any) => {
          if (photos.length) {
            for (let index = 0; index < photos.length; index++) {
              deletePhoto(photos[index]);
            }
          }
        });
      }
  });


  return (
    <IonModal
      trigger="update-modal"
      presentingElement={presentingElement}
      ref={modal}
      onWillDismiss={(ev) => onWillDismiss(ev)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => {
              if (photos.length) {
                for (let index = 0; index < photos.length; index++) {
                  deletePhoto(photos[index]);
                }
              }
              modal.current?.dismiss();
            }}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonText color="light" className="ion-text-center">
            <h4>Update Akun</h4>
          </IonText>
          <IonButton slot="end" fill="clear"></IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonCard>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol
                class="ion-align-self-center"
                size-md="12"
                size-lg="12"
                size-xs="12"
              >
                <IonItem>
                  <IonInput
                    labelPlacement="floating"
                    label="User Name"
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    value={name}
                    required
                    onIonInput={(e: any) => setName(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    labelPlacement="floating"
                    label="User Login"
                    type="text"
                    placeholder="Your UserName"
                    name="email"
                    value={login}
                    required
                    onIonInput={(e: any) => setLogin(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    type="text"
                    labelPlacement="floating"
                    label="Email"
                    placeholder="Your Email"
                    name="email"
                    value={email}
                    required
                    onIonInput={(e: any) => setEmail(e.detail.value!)}
                  />
                </IonItem>
                <IonItem onClick={() => selectImageSource()}>
                  <IonText slot="start"> Upload File</IonText>
                  <IonIcon slot="end" src={cameraOutline}></IonIcon>
                </IonItem>
                <IonButton expand="block" onClick={updateProfile}>
                  Update
                </IonButton>
              </IonCol>
              <IonCol>
                {photos[0]?.webviewPath ? (
                  <IonImg src={photos[0]?.webviewPath ?? imageProfile} />
                ) : (
                  <IonImg
                    src={`${baseUrl}/media/images/profile/${user.avatar}`}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default Index;
