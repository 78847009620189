export const Rupiah = (number:number) => {
  return new Intl.NumberFormat("id-ID", {
    // style: "currency",
    currency: "IDR"
  }).format(Math.round(number));
};

export const getNameDate = (value: string = "", timeShow: boolean = false) => {
  let date;
  if (value) {
    date = new Date(value);
  } else {
    date = new Date();
  }
  if (date instanceof Date && !isNaN(date.valueOf())) {
    const nameDay = date.toLocaleString("id-ID", { weekday: "long" });
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const time = timeShow
      ? " " + date.getHours() + ":" + date.getMinutes()
      : "";
    return `${nameDay}, ${day}-${month}-${year} ${time}`;
  } else {
    return "-";
  }
};


export const groupBy = (array:any, key:string) => {
  return array.reduce((result:any, currentValue:any) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const getRoleMenu = (array: any, url: string) => {
  return array.find((val: any) => val.menu.menu_link === url);
};

export const sayNumber = (
  num: number | null = null,
  dec: number = 2
): string | boolean => {
  const stext: string[] = [
    "Nol",
    "Satu",
    "Dua",
    "Tiga",
    "Empat",
    "Lima",
    "Enam",
    "Tujuh",
    "Delapan",
    "Sembilan",
    "Sepuluh",
    "Sebelas",
  ];
  const say: string[] = [
    "Ribu",
    "Juta",
    "Milyar",
    "Triliun",
    "Biliun",
    "--unknown---", // Adjust this if known
  ];
  let w: string = "";

  if (num === null || isNaN(num)) {
    return false;
  }

  if (num < 0) {
    w = "Minus ";
    // Make positive
    num *= -1;
  }

  const snum: string = num.toFixed(dec).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const strnum: string[] = snum.split(".")[0].split(",");

  const koma: string = snum.split(".")[1] || "";

  const isone: boolean = strnum[0].charAt(0) === "1";

  if (strnum.length === 1) {
    const parsedNum: number = parseInt(strnum[0]);
    switch (strnum[0].length) {
      case 1:
      case 2:
        if (parsedNum < 20) {
          w += stext[parseInt(strnum[0].charAt(1))] + " Belas";
        } else {
          w +=
            stext[parseInt(strnum[0].charAt(0))] +
            " Puluh " +
            (parseInt(strnum[0].charAt(1)) === 0
              ? ""
              : stext[parseInt(strnum[0].charAt(1))]);
        }
        break;
      case 3:
        w +=
          (isone
            ? "Seratus"
            : sayNumber(parseInt(strnum[0].charAt(0))) + " Ratus") +
          " " +
          (parseInt(strnum[0].slice(1)) === 0
            ? ""
            : sayNumber(parseInt(strnum[0].slice(1))));
        break;
      case 4:
        w +=
          (isone
            ? "Seribu"
            : sayNumber(parseInt(strnum[0].charAt(0))) + " Ribu") +
          " " +
          (parseInt(strnum[0].slice(1)) === 0
            ? ""
            : sayNumber(parseInt(strnum[0].slice(1))));
        break;
      default:
        break;
    }
  } else {
    const text: string = say[strnum.length - 2];
    w +=
      isone && strnum[0].length === 1 && strnum.length <= 3
        ? "Satu " + text.toLowerCase()
        : sayNumber(parseInt(strnum[0])) + " " + text;
    strnum.shift();
    let i: number = strnum.length - 2;
    strnum.forEach((v: string, k: number) => {
      if (parseInt(v)) {
        w += " " + sayNumber(parseInt(v)) + " " + (i >= 0 ? say[i] : "");
      }
      i--;
    });
  }
  w = w.trim();
  if ((dec = parseInt(koma))) {
    w += " Koma " + sayNumber(parseInt(koma));
  }
  
  let textNumber: string = w.trim().toUpperCase() + " Rupiah";
  // Limit string length
  textNumber = textNumber.length > 80 ? textNumber.substring(0, 77) + '...' : textNumber;

  return textNumber;
};