import { IonContent, IonGrid, IonRow, IonCol, IonImg, IonPage, IonText, IonFooter, IonToolbar, IonItem, IonInput, IonButton, IonIcon, useIonAlert, useIonLoading, useIonToast} from '@ionic/react';
import React, { useState } from 'react';
import { personCircle, eye, businessOutline, keyOutline, wifiOutline, syncSharp, happyOutline, warningOutline } from "ionicons/icons";
import { Device } from '@capacitor/device';
import { RestApi } from './../services/Api';
import { useAuth } from "./../data/sessions/Auth";
import { RouteComponentProps } from "react-router";
import { UserAction } from "../data/state/UserReducer";
import { useDispatch } from "react-redux";
import { Preferences } from '@capacitor/preferences';
import { Browser } from '@capacitor/browser';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import { Directory, DownloadFileResult, Filesystem } from '@capacitor/filesystem';
import { FileOpener, FileOpenerOptions } from "@capacitor-community/file-opener";
import './Login.scss';

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  let { logIn } = useAuth()!;
  const [presentToast] = useIonToast();
  const dispatch = useDispatch();
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [email, setEmail] = useState<string>("");
  const [port, setPort] = useState<any>(window.localStorage.getItem('port')??'');
  const [password, setPassword] = useState<string>("");
  const [url, setUrl] = useState<any>(process.env.REACT_APP_URL_MAIN);
  const [hide, setHide] = useState<boolean>(true);
  const [portMain, setPortMain] = useState<boolean>(false);
  const [portBackup, setPortBackup] = useState<boolean>(false);
  const [presentLoading, dismissLoading] = useIonLoading();

  const massageAlert = async (status:string,body:string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{cssClass: 'alert-button-confirm', text: 'Ok' }],
    })
  }
  const handleLogin = async (e:any) => {
    try {
      e.preventDefault()      
      if (!email || !password || !port) {
          presentToast({
            message: t("required"),
            duration: 1500,
            icon: warningOutline
          })
          return;
      }
      await Preferences.set({ key: "url", value: url + port });
      const info = await Device.getId();
      await presentLoading({message:'Harap Tunggu'})
      await RestApi("login", "post", {
        email: email,
        password: password,
        imei: info.identifier
      })
        .then(async (res: any) => {
          if (res.data.code === 200) {
            dispatch(UserAction.setProfile(res.data.data));
            await Preferences.set({ key: "token", value: res.data.token });
            presentToast({
              message: "Hello " + res.data.data.user_name,
              duration: 1500,
              icon: happyOutline,
            });
            logIn();
            history.replace("/home");
            dismissLoading();
          } else {
            try {
              let messageObj = JSON.parse(res.data.message);
              let message = "";
              for (var key in messageObj) {
                // skip loop if the property is from prototype
                if (!messageObj.hasOwnProperty(key)) continue;

                var obj = messageObj[key];
                for (var prop in obj) {
                  // skip loop if the property is from prototype
                  if (!obj.hasOwnProperty(prop)) continue;

                  // your code
                  message += key + " = " + obj[prop];
                }
              }
              massageAlert("error", message);
            } catch (error) {
              massageAlert("error", res.data.message);
            }
            dismissLoading();
          }
        })
        .catch((error: any) => {
          let message = "";
          if (
            error.message ===
            "Failed to execute 'open' on 'XMLHttpRequest': Invalid URL"
          ) {
            message = "Maaf Tidak Ada ID Company";
          } else {
            message = error.message;
          }
          dismissLoading();
          massageAlert("error", message);
        });
    } catch (error:any) {
      massageAlert('error',error.message)
    }
  };
  
  const urlSelect = () => {
    alert({
      header: 'Pilih Koneksi',
      cssClass: 'header-alert',
      inputs: [
        {
          label: 'Utama',
          type: 'radio',
          value: process.env.REACT_APP_URL_MAIN,
          checked: url === process.env.REACT_APP_URL_MAIN,
        },
        {
          label: 'Backup',
          type: 'radio',
          value: process.env.REACT_APP_URL_BACKUP,
          checked: url === process.env.REACT_APP_URL_BACKUP,
        }
      ],
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-button-confirm',
          role: 'confirm',
          handler: async (data) => {
            setUrl(data)
            if (data === process.env.REACT_APP_URL_BACKUP) {
              if (!portMain) {
                setPort(Number(port) - 1)
                setPortMain(true)
              }else{
                setPortBackup(false)
              }
            }else{
              if (!portBackup) {
                setPort(Number(port) + 1)
                setPortBackup(true)
              }else{
                setPortMain(false)
              }
            }
          },
        },
      ],
    })
  }

  const forgotPassword = () => {
    alert({
      header: 'Masukan Email',
      cssClass: 'header-alert',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: async (data) => {
            if (!port || !data.forgot_email) {
              presentToast({
                message: t("required"),
                duration: 1500,
                icon: warningOutline
              })
              return;
            }
            await Preferences.set({key: "url",value: url+port});
            await presentLoading({message:'Harap Tunggu'})
            await RestApi('forgot','post',{
              "email" : data.forgot_email,
            }).then(async (res:any) => {
                if (res.data.code === 200) {
                  massageAlert('success',res.data.message) 
                  dismissLoading()
                }else{
                  try {
                    let messageObj = JSON.parse(res.data.message)
                    let message = '';
                    for (var key in messageObj) {
                      // skip loop if the property is from prototype
                      if (!messageObj.hasOwnProperty(key)) continue;
        
                      var obj = messageObj[key];
                      for (var prop in obj) {
                          // skip loop if the property is from prototype
                          if (!obj.hasOwnProperty(prop)) continue;
        
                          // your code
                          message += key + " = " + obj[prop]
                      }
                    }
                    massageAlert('error',message)  
                  } catch (error) {
                    massageAlert('error',res.data.message)  
                  }
                  dismissLoading()
                }    
            })
            .catch((error:any)=>{
              let message = '';
              if (error.message === "Failed to execute 'open' on 'XMLHttpRequest': Invalid URL") {
                message = 'Maaf Tidak Ada ID Company';
              }else{
                message = error.message
              }
              dismissLoading()
              massageAlert('error',message)
            })
          },
        },
      ],
      inputs: [
        {
          placeholder: 'Email',
          type: 'email',
          name: 'forgot_email',
        },
         {
          placeholder: 'ID Company',
          type: 'number',
          value: port,
          name: 'forgot_port'
        }
      ],
    })
  }

  const checkUpdate = async () => {
    if (!port) {
      presentToast({
        message: t("required") + " ID Company",
        duration: 1500,
        icon: warningOutline,
      });
      return;
    }
    await Preferences.set({ key: "url", value: url + port });
    await presentLoading({message:'Harap Tunggu'})
    await RestApi('apk?version='+process.env.REACT_APP_VERSION,'get',{
    }).then(async (res:any) => {
        if (res.data.code === 200) {
          if (Capacitor.isNativePlatform()) {
            Filesystem.downloadFile({
              url: res.data.message,
              path: `cos-V${res.data.message.replace(/[^0-9]/g, "")}.apk`,
              directory: Directory.Documents,
            }).then((res: DownloadFileResult) => {
              massageAlert(
                "success",
                "Silahkan cek folder document penyimpanan HP anda untuk menginstall secara manual versi terbaru"
              );
              _openFileWithType(
                res.path,
                "application/vnd.android.package-archive"
              );
            });
          } else {
            Browser.open({ url: res.data.message });
          }
          dismissLoading();
        }else{
          massageAlert('success',res.data.message)  
          dismissLoading()
        }    
    })
    .catch((error:any)=>{
      dismissLoading()
      massageAlert('error',error.message)
    })
  }

  const _openFileWithType = async (filePath: any, fileType: string) => {
    const fileOpenerOptions: FileOpenerOptions = {
      filePath: filePath,
      contentType: fileType,
    };

    await FileOpener.open(fileOpenerOptions)
      .then(() => {
        // 'File is opened'
      })
      .catch((error: any) => {
        massageAlert("error", error.message);
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen class="login-content">
        <IonGrid className="contaier-login ion-padding">
          <IonRow className="ion-no-padding">
            <IonCol className="ion-no-padding"></IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol className="ion-no-padding">
              <IonText className="ion-text-center">
                <IonImg
                  className="logo"
                  src={"assets/img/logo-gforce.png"}
                />
              </IonText>
              <IonItem>
                <IonIcon
                  color="light"
                  slot="start"
                  size="small"
                  icon={personCircle}
                />
                <IonInput
                  color="light"
                  type="text"
                  placeholder="Username"
                  value={email}
                  onIonInput={(e: any) => setEmail(e.detail.value!)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonIcon
                  color="light"
                  slot="start"
                  size="small"
                  onClick={() => {
                    hide ? setHide(false) : setHide(true);
                  }}
                  icon={password ? eye : keyOutline}
                />
                <IonInput
                  color="light"
                  type={hide ? "password" : "text"}
                  placeholder="Password"
                  value={password}
                  onIonInput={(e: any) => setPassword(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonIcon
                  color="light"
                  slot="start"
                  size="small"
                  icon={businessOutline}
                />
                <IonInput
                  color="light"
                  type="number"
                  placeholder="ID Company"
                  value={port}
                  onIonInput={(e: any) => setPort(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>
              <IonText
                color="light"
                className="ion-float-right ion-padding"
                onClick={forgotPassword}
              >
                Lupa Password ?
              </IonText>
              <IonButton
                expand="block"
                type="submit"
                className="ion-padding"
                onClick={handleLogin}
              >
                Login
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFooter className="ion-no-border">
          <IonToolbar className="login-footer">
            <IonRow className="ion-justify-content-center">
              <IonCol size="3" className="ion-no-padding"></IonCol>
              <IonCol size="3">
                <IonText
                  color="light"
                  className="ion-text-center ion-no-padding"
                  onClick={checkUpdate}
                >
                  <IonIcon
                    className="ion-no-padding"
                    color="light"
                    size="large"
                    icon={syncSharp}
                  />
                  <p>
                    <strong>Update</strong>
                  </p>
                </IonText>
              </IonCol>
              <IonCol size="3">
                <IonText
                  color="light"
                  className="ion-text-center ion-no-padding"
                  onClick={urlSelect}
                >
                  <IonIcon
                    className="ion-no-padding"
                    color="light"
                    size="large"
                    icon={wifiOutline}
                  />
                  <p>
                    <strong>Koneksi</strong>
                  </p>
                </IonText>
              </IonCol>
              <IonCol size="3" className="ion-no-padding"></IonCol>
            </IonRow>
          </IonToolbar>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Login;
