import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const customerReducer = createSlice({
  name: "Customer",
  initialState: {},
  reducers: {
    setCustomer: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.value = action.payload;
    },
    updateCustomer: (state: any, action: PayloadAction<any>) => {
      for ( var property in action.payload ) {
        state.value[property] = action.payload[property]
      }
    },
  },
});

export const CustomerReducer = customerReducer.reducer;
export const CustomerAction = customerReducer.actions;