import {
    IonPage,
    IonContent,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonBadge,
    IonFooter
} from '@ionic/react';
import React, { useState } from 'react';
import { RestApi } from '../../services/Api';
import { arrowBackOutline} from 'ionicons/icons';
import { Customer } from '../../models/Modal'
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';

import './Sales.scss';
const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Sales: React.FC<Customer> = (props) =>{
    let totalItem : number = 0;
    let totalPeriod : number = 0;
    const { t } = useTranslation(["general"]);
    const [alert] = useIonAlert();
    const [listSales, setListSales] = useState<any>([]);
    const [customer, setCustomer] = useState<any>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const massageAlert = async (status:string,body:string) => {
        alert({
            header: t(status),
            message: body,
            cssClass: `header-alert-${status}`,
            buttons: [{ text: "Ya", cssClass: "alert-button-confirm", role: "confirm" }]
        })
    }

    const getArList = async (page:string) => {
        let startDate = new Date(new Date().setMonth(new Date().getMonth() - 12));
        let endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        await RestApi(`${page}`,'post',{
            start_month : startDate.toISOString().slice(0, 10),
            end_month : endDate.toISOString().slice(0, 10),
        },'application/json')
        .then((res:any) => {
            // let orderedDates : any = {};
            // Object.keys(res.data.data.sales).sort(function(a, b) {
            //     return +new Date(b) - +new Date(a) 
            // }).forEach(function(key) {
            //     orderedDates[key] = res.data.data.sales[key];
            // })

            if (res.data.code === 200) {
                setListSales(res.data.data.sales)
                setCustomer({
                    customer_name:res.data.data.customer.customer_name,
                    customer_id:res.data.data.customer.customer_id,
                })
                setLoaded(true)
            }else{
                massageAlert('error',res.data.message)
                props.onDismissDetail()
            }
        })
        .catch((err:any) => {
            props.onDismissDetail()
            massageAlert('error',err.message)
        });
    }

    const countItem = (count:number) => {
        totalItem = totalItem + count;
        totalPeriod = totalPeriod + count;
        return count;
    }
    const removeTotalPeriod = () => {
        totalPeriod = 0
        return ' ';
    }
    useConstructor(() => {
        getArList(`si/customer-sales/${props.idCustomer}`)
    });
    

    return (
        <IonPage>
            <IonHeader className='toolbar-sales'>
            {loaded ?
                <IonToolbar className='ion-no-padding'>
                    <IonButton color="light"slot="start" fill="clear" onClick={() => props.onDismissDetail()}>
                        <IonIcon color="light" slot='start' src={arrowBackOutline}/>
                    </IonButton>
                    <IonLabel color='light' className='ion-text-center ion-no-padding'>
                        <h1>Penjualan</h1>
                        <h4><strong>{customer.customer_name} ({customer.customer_id})</strong></h4>
                    </IonLabel >
                    <IonLabel slot='end' className='ion-padding'>
                        <IonBadge color="light" slot='end' >{listSales.length}</IonBadge>
                    </IonLabel>
                </IonToolbar>
            :<IonToolbar> 
                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
            </IonToolbar>
            } 
            </IonHeader>
            <IonContent className='content-sales'>
                {!loaded &&
                <IonList>
                    <IonListHeader>
                        <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
                    </IonListHeader>
                    {loadList.map((value:any, key:any) => (
                        <IonItem key={key}>
                            <IonThumbnail slot="start">
                                <IonSkeletonText animated={true}></IonSkeletonText>
                            </IonThumbnail>
                            <IonLabel>
                            <h3>
                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                            </h3>
                            <p>
                                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                            </p>
                            <p>
                                <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                            </p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                }
                {listSales && loaded ?
                <IonList className="main-card">
                    {Object.keys(listSales).map((keyGroup, i) => (
                        <IonCard key={i}>
                            <IonItem>
                                <IonLabel className='ion-no-padding ion-text-center' color='dark'>
                                    <h1><strong>{removeTotalPeriod()} {keyGroup}</strong></h1>
                                </IonLabel>
                            </IonItem>
                            <IonCardContent className='ion-padding'>
                                {/* <IonItem className='ion-no-padding'> */}
                                    {Object.keys(listSales[keyGroup]).map((keyList, i) => (
                                        <IonText color='dark' key={i}>
                                            <h4>- <strong>{listSales[keyGroup][keyList].detail.grouping_name} </strong></h4>
                                        {Object.keys(listSales[keyGroup][keyList].list).map((key, i) => (
                                            <h4 className='item-product' key={i}>  {listSales[keyGroup][keyList].list[key][0].item_name} 
                                                <span className='span-float'> <strong>{countItem(listSales[keyGroup][keyList].list[key].reduce((a:any, b:any) => +a + +b.sum_sales_quantity, 0))}</strong></span>
                                            </h4>
                                        ))}
                                        </IonText>
                                    ))}
                                {/* </IonItem> */}
                                <IonLabel color='dark' className='ion-no-padding' slot='start'>
                                    <h3 className='item-product'> <strong>Total Qty</strong>
                                        <span className='span-float'><strong>{totalPeriod} </strong> </span>

                                    </h3>
                                </IonLabel>
                            </IonCardContent>
                        </IonCard>
                    ))}
                </IonList>
                :<IonImg className='img-empty' src={"assets/img/search-empty.webp"} />
                }
            </IonContent>
            <IonFooter className='toolbar-ar ion-no-padding'>
                {loaded ?
                    <IonToolbar className='ion-no-padding'>
                        <IonItem className='ion-no-padding'>
                            <IonText color='light' className='ion-padding-start' slot='start'>
                                <p><span>{Object.keys(listSales)[0]} - {Object.keys(listSales)[Object.keys(listSales).length - 1]}</span> <br /><strong>Total Qty </strong> </p>
                            </IonText>
                            <IonText color='light' className='ion-padding-end' slot='end'>
                                <br />
                                <p><strong>{totalItem}</strong> </p>
                            </IonText>
                        </IonItem>              
                    </IonToolbar>
                :<IonToolbar> 
                    <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
                    <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
                </IonToolbar>
                } 
                
            </IonFooter>
        </IonPage>
    );
};

export default Sales;
