import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonCard,
  IonItem,
  IonList,
  IonText,
  IonButton,
  IonImg,
  IonAvatar,
  IonToggle,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { arrowBackOutline, logOutOutline, personCircleOutline, keyOutline, moon, languageOutline } from "ionicons/icons";
import ChangePassword from './../components/profile/ChangePassword';
import Update from './../components/profile/Update';
import { useAuth } from "./../data/sessions/Auth";
import React, {useState} from 'react';
import { Preferences } from '@capacitor/preferences'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './Profile.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Index: React.FC = () => {
  const { logOut } = useAuth()!;
  const [baseUrl, setBaseUrl] = useState<any>();
  const user = useSelector((state:any) => state.user.value);
  const [darkMode, setDarkMode] = useState<any>(false);
  const { t, i18n } = useTranslation(["general"]);
  const languageChange = (e: any) => {
    i18n.changeLanguage(e); //change the language
  };

  useConstructor(async () => {
    const url =  await Preferences.get({key: "url" });
    setBaseUrl(url.value)
    if (!user) {
      logOut()
    }
  });

  const toggleDarkModeHandler = (e: any) => {
    setDarkMode(e.detail.checked);
    document.body.setAttribute(
      "data-theme",
      e.detail.checked ? "dark" : "light"
    );
    Preferences.set({ key: "dark", value: e.detail.checked });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            routerLink="/home"
            routerDirection="back"
            className=""
            size="small"
          >
            <IonIcon color="light" src={arrowBackOutline} />
          </IonButton>
          <IonText className="ion-text-center" color="light">
            <h4>Akun</h4>
          </IonText>
          <IonButton
            fill="clear"
            slot="end"
            color="danger"
            onClick={async () => {
              await logOut();
            }}
          >
            <IonIcon src={logOutOutline}></IonIcon>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-content">
        <div className="card">
          <div className="header">
            <div className="avatar">
              <IonAvatar>
                <IonImg
                  className="img-avatar"
                  src={baseUrl + "/image/profile/" + user.user_avatar}
                />
              </IonAvatar>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="user-meta ion-text-center">
            <h6 className="country">
              {user.user_title} <br />
              {user.user_salesman_id}{" "}
            </h6>
            <h3 className="playername">{user.user_name}</h3>
            <h5 className="country">{user.user_email}</h5>
          </div>
        </div>
        <IonCard>
          <IonList>
            <IonItem id="update-modal">
              <IonIcon slot="start" src={personCircleOutline} />
              <IonLabel>{t("module.profile")}</IonLabel>
            </IonItem>
            <IonItem id="change-password-modal">
              <IonIcon slot="start" src={keyOutline} />
              <IonLabel>{t("change-password")}</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonIcon slot="start" icon={moon} />
              <IonLabel>{t("dark-mode")}</IonLabel>
              <IonToggle
                slot="end"
                checked={darkMode}
                onIonChange={(e) => toggleDarkModeHandler(e)}
              />
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={languageOutline} />
              <IonSelect
                label={t("language") ?? ""}
                interface="action-sheet"
                aria-label={t("language") ?? ""}
                value={i18n.language}
                onIonChange={(e) => {
                  languageChange(e.detail.value);
                }}
                placeholder={t("language") ?? ""}
              >
                <IonSelectOption value="id">Indonesia</IonSelectOption>
                <IonSelectOption value="en">English</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonList>
        </IonCard>
      </IonContent>
      <Update />
      <ChangePassword />
    </IonPage>
  );
};

export default Index;
