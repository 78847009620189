import {
    IonPage,
    IonContent,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonBadge,
} from '@ionic/react';
import React, { useState } from 'react';
import { getNameDate, groupBy, Rupiah } from '../../services/Library';
import { RestApi } from '../../services/Api';
import { arrowBackOutline} from 'ionicons/icons';
import { Customer } from '../../models/Modal'
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';
import './Payment.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Payment: React.FC<Customer> = (props) =>{
    const { t } = useTranslation(["general"]);
    const [alert] = useIonAlert();
    const [listPayment, setListPayment] = useState<any>([]);
    const [customer, setCustomer] = useState<any>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const massageAlert = async (status: string, body: string) => {
      alert({
        header: t(status),
        message: body,
        cssClass: `header-alert-${status}`,
        buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
      });
    };
    
    const getArList = async (page:string) => {
        await RestApi(`${page}`,'get',{},'application/json')
        .then((res:any) => {
            if (res.data.code === 200) {
                setListPayment(groupBy(res.data.data, 'si_id'))
                setCustomer({
                    so_customer_name:res.data.data[0].si_customer_name,
                    so_customer_id:res.data.data[0].srec_customer_id,
                })
                setLoaded(true)
            }else{
                massageAlert('error',res.data.message)
                props.onDismissDetail()
            }
        })
        .catch((err:any) => {
            massageAlert('error',err.message)
            props.onDismissDetail()
        });
    }

    const checkPaid = (item:any) =>{
        let totalItem = item.reduce((a:any, b:any) => +a + +b.srecdet_payment_amount, 0)
        if (Math.abs(Math.round(totalItem)-Math.round(item[0].si_total_amount)) < 1000 ) {
            return (
              <IonBadge
                color="success"
                style={{ marginRight: ".5rem" }}
                className="ion-float-start"
              >
                Lunas
              </IonBadge>
            );
        }else{
            return (
              <IonBadge
                style={{ marginRight: ".5rem" }}
                color="danger"
                className="ion-float-start"
              >
                Belum
              </IonBadge>
            );
        }
    }

    useConstructor(() => {
        getArList(`srec/payment/${props.idCustomer}`)
    });

    return (
        <IonPage>
            <IonHeader className='toolbar-payment'>
            {loaded ?
                <IonToolbar className='ion-no-padding'>
                    <IonButton color="light"slot="start" fill="clear" onClick={() => props.onDismissDetail()}>
                        <IonIcon color="light" slot='start' src={arrowBackOutline}/>
                    </IonButton>
                    <IonLabel color='light' className='ion-text-center ion-no-padding'>
                        <h1>Pembayaran</h1>
                        <h4> <strong>{customer.so_customer_name} ({customer.so_customer_id})</strong></h4>
                    </IonLabel >
                    <IonLabel slot='end' className='ion-padding'>
                        <IonBadge color="light" slot='end' >{listPayment.length}</IonBadge>
                    </IonLabel>
                </IonToolbar>
            :<IonToolbar> 
                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
            </IonToolbar>
            } 
            </IonHeader>
            <IonContent className='content-payment'>
                {!loaded &&
                <IonList>
                    <IonListHeader>
                        <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
                    </IonListHeader>
                    {loadList.map((value:any, key:any) => (
                        <IonItem key={key}>
                            <IonThumbnail slot="start">
                                <IonSkeletonText animated={true}></IonSkeletonText>
                            </IonThumbnail>
                            <IonLabel>
                            <h3>
                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                            </h3>
                            <p>
                                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                            </p>
                            <p>
                                <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                            </p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                }
                {listPayment && loaded ?
                <IonList className="main-card">
                    {Object.keys(listPayment).map((keyName, i) => (
                        <IonCard className="ion-padding" key={i}>
                            <IonItem className='ion-no-padding' >
                                <IonLabel className='ion-no-padding'>
                                    <span>{getNameDate(listPayment[keyName][0].si_date)}</span>
                                    <h2>
                                        <strong>{listPayment[keyName][0].si_id}</strong>
                                    </h2>
                                </IonLabel>
                                <IonLabel slot='end' className='ion-no-padding'>
                                    <span>
                                        <IonBadge color='waning'><strong>JT :  {listPayment[keyName][0].si_due_date} </strong></IonBadge>
                                    </span>
                                    <span> 
                                       {checkPaid(listPayment[keyName])} {' '} <IonBadge color='medium' className='ion-float-end'>{Rupiah(listPayment[keyName][0].si_total_amount)}</IonBadge>
                                    </span>
                                </IonLabel>
                            </IonItem>
                            <IonCardContent className='ion-no-padding'>
                                {listPayment[keyName].map((value:any, key:any) => (
                                    <IonItem className='ion-no-padding' key={key}>
                                        <IonText>
                                            <p>- {value.srec_id} </p>
                                            <p>{Rupiah(value.srecdet_payment_amount)}</p>
                                        </IonText>
                                        <IonBadge slot='end'>{getNameDate(value.srec_date)}</IonBadge>
                                    </IonItem> 
                                ))}
                            </IonCardContent>
                        </IonCard>
                    ))}
                </IonList>
                :<IonImg className='img-empty' src={"assets/img/search-empty.webp"} />
                }
            </IonContent>
        </IonPage>
    );
};

export default Payment;
