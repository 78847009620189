import {
    IonContent,
    IonPage,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonBadge,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonInput,
    IonInfiniteScroll, 
    IonInfiniteScrollContent,
    useIonToast,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
} from '@ionic/react';
import React, { useState } from 'react';
import { storefrontOutline, searchOutline, arrowBackOutline, warningOutline, chevronDownCircleOutline } from 'ionicons/icons';
import { RestApi } from './../services/Api';
import { loadList } from '../models/Data';
import { useTranslation } from 'react-i18next';
import './Bill.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Bill: React.FC = () => {
  const [presentToast] = useIonToast();
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const [listBill, setListBill] = useState<any>([]);
  const [dataBill, setDataBill] = useState<any>([]);
  const massageAlert = async (status:string,body:string) => {
      alert({
          header: t(status),
          message: body,
          cssClass: `header-alert-${status}`,
          buttons: [{
              cssClass: 'alert-button-confirm', 
              text: 'Ok'
          }],
      })
  }

  const getBillList = async (page:string,next:boolean) => {
      await RestApi(page,'get',{},'application/json')
      .then((res:any) => {
          if (res.data.code === 200) {
              setDataBill(res.data.data)
              if (next) {
                  setListBill((prevState: any) => [
                  ...prevState,
                  ...res.data.data.data,
                  ]);
              }else{
                  setListBill(res.data.data.data)
              }
          }else{
              setListBill([])
              setDataBill([])
              presentToast({
                message: res.data.message,
                duration: 2500,
                color: "danger",
                icon: warningOutline,
              }); 
          }
          setLoaded(true)
      })
      .catch((err:any) => {
          massageAlert('error',err.message)
          setLoaded(true)
      });
  }

  const loadData = (ev: any) => {
      if (dataBill.current_page === dataBill.last_page) {
          setInfiniteDisabled(true);
          return;
      }
      getBillList(`bill?page=${dataBill.current_page+1}&search=${searchText}`,true)
      ev.target.complete();
  }  

  const onChangeSearch = ((e:any) => {
      setSearchText(e.detail.value!)
      getBillList(`bill?page=1&search=${e.detail.value!}`,false)
  })

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getBillList(`bill?page=1&search=${searchText}`, false);
    event.detail.complete();
  };
  
  useConstructor(() => {
      getBillList(`bill?page=1&search=${searchText}`,false)
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="toolbar-bill">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            routerLink="/home"
            routerDirection="back"
            className=""
            size="small"
          >
            <IonIcon color="light" src={arrowBackOutline} />
          </IonButton>
          <div className="search" slot="end">
            <IonItem lines="none" className="item-bill">
              <IonInput
                placeholder={t("search") + " " + t("module.bill")}
                className="input-bill"
                onIonInput={(e) => onChangeSearch(e)}
              ></IonInput>
              <IonIcon src={searchOutline} slot="start" />
              <IonBadge color="medium">{dataBill.total}</IonBadge>
            </IonItem>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bill-content">
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t("loading") ?? ""}
            refreshingSpinner="circles"
            refreshingText={t("loading") ?? ""}
          ></IonRefresherContent>
        </IonRefresher>
        {listBill.length && loaded ? (
          <IonList>
            {listBill.map((value: any, key: any) => (
              <IonCard className="ion-padding" key={key}>
                <IonItem className="ion-no-padding">
                  <IonIcon
                    size="small"
                    className="ion-no-padding"
                    src={storefrontOutline}
                  />
                  <IonLabel className="ion-no-padding">
                    <h4>
                      <strong>{value.customer_name}</strong>
                    </h4>
                    <span>
                      <strong>Total : Rp {value.bill_total_amount}</strong>
                    </span>
                  </IonLabel>
                  <IonBadge slot="end" color="dark">
                    {value.bill_id}
                  </IonBadge>
                </IonItem>
                <IonCardContent className="ion-no-padding">
                  <IonItem className="ion-no-padding" lines="none">
                    <IonLabel className="ion-no-padding">
                      <p>{value.customer_address1}</p>
                    </IonLabel>
                    <IonLabel slot="end" className="ion-no-padding">
                      <p>{value.customer_phone}</p>
                    </IonLabel>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
          </IonList>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          >
            {dataBill.next_page_url && (
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            )}
          </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Bill;
