import {
  IonPage,
  IonContent,
  IonSkeletonText,
  IonIcon,
  IonLabel,
  IonCard,
  IonItem,
  IonList,
  IonListHeader,
  IonThumbnail,
  IonImg,
  useIonAlert,
  IonText,
  IonHeader,
  IonToolbar,
  IonButton,
  IonBadge,
  IonRow,
  IonCol,
  useIonModal,
  useIonToast,
} from '@ionic/react';
import React, { useState} from 'react';
import { getNameDate } from '../../services/Library';
import { arrowBackOutline, checkmarkCircleOutline, chevronBackCircleOutline, chevronForwardCircleOutline, closeCircleOutline, sadOutline } from 'ionicons/icons';
import { RestApi } from '../../services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { loadList } from '../../models/Data';
import Detail from "./Detail";
import { useTranslation } from 'react-i18next';
import { CustomerAction } from '../../data/state/CustomerReducer';
import "./Calendar.scss";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Calendar: React.FC<any> = (props) =>{
    const user = useSelector((state: any) => state.user.value);
    const today = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const dispatch = useDispatch();
    const [alert] = useIonAlert();
    const { t } = useTranslation(["general"]);
    const [listCalendar, setListCalendar] = useState<any>(null);
    const [getDetailVisit, setGetDetailVisit] = useState<any>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [searchDate, setSearchDate] = useState<any>(
      today.toISOString().slice(0, 10)
    );
    const [presentToast] = useIonToast();

    const massageAlert = async (status:string,body:string) => {
      alert({
          header: t(status),
          message: body,
          cssClass: `header-alert-${status}`,
          buttons: [{ text: 'Ok', handler: (d:any) => {} }]
      })
    }
    const handleDismissDetail = (e: any) => {
      dismissDetail();
    };
    
    const [presentDetail, dismissDetail] = useIonModal(Detail, {
      detailVisit: getDetailVisit,
      onDismissDetail: handleDismissDetail,
      type : "calender",
    });


    const currentDate = (param: number) => {
      let currentDate = new Date(searchDate);
      setListCalendar(null);
      setLoaded(false);
      switch (param) {
        case 1:
          let nextDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 2,
            0
          );
          setSearchDate(nextDate.toISOString().slice(0, 10));
          getCalendarList(`${nextDate.toISOString().slice(0, 10)}`);
          break;
        case 2:
          let backDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            -1
          );
          setSearchDate(backDate.toISOString().slice(0, 10));
          getCalendarList(`${backDate.toISOString().slice(0, 10)}`);
          break;
        default:
          break;
      }
    };

    const getCalendarList = async (date: string) => {
      await RestApi(
        `visit/calendar-visit/${date}`,
        "get",
        {},
        "application/json"
      )
        .then((res: any) => {
          if (res.data.code === 200) {
            setListCalendar(res.data.data);
          } else {
            setListCalendar(null);
            presentToast({
              message: res.data.message,
              duration: 1500,
              color: "danger",
              icon: sadOutline,
            });
          }
          setLoaded(true);
        })
        .catch((err: any) => {
          props.onDismissCalendar();
          massageAlert('error', err.message);
        });
    };

    const getVisitDetail = async (value: any) => {
      dispatch(CustomerAction.setCustomer(value))
      setGetDetailVisit(value);
      presentDetail();
    };

    useConstructor(() => {
      setSearchDate(today.toISOString().slice(0, 10))
      getCalendarList(`${today.toISOString().slice(0, 10)}`);
    });

    return (
      <IonPage>
        <IonHeader className="toolbar-history">
          <IonToolbar className="ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismissCalendar()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonLabel color="light" className="ion-text-center ion-no-padding">
              <h1>List Calendar</h1>
            </IonLabel>
            <IonButton color={"light"} slot="end" fill="clear"></IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent className="content-history">
          <IonCard className="ion-no-padding">
            <IonRow className="ion-no-padding">
              <IonCol size="2" className="ion-no-padding">
                <br />
                <IonText color="dark" className="ion-no-padding">
                  <IonIcon
                    size="large"
                    className="ion-padding ion-float-left"
                    icon={chevronBackCircleOutline}
                    onClick={() => {
                      currentDate(2);
                    }}
                  />
                </IonText>
              </IonCol>
              <IonCol size="8" className="ion-no-padding">
                <IonLabel
                  color="dark"
                  className="ion-text-center ion-no-padding"
                >
                  <p>{getNameDate()}</p>
                  <h2>
                    <strong>{searchDate.substring(0, 7)}</strong>
                  </h2>
                  <p>
                    {user.user_name} ({user.user_salesman_id})
                  </p>
                </IonLabel>
              </IonCol>
              <IonCol size="2" className="ion-no-padding">
                <IonText color="dark" className="ion-no-padding">
                  <br />
                  <IonIcon
                    size="large"
                    className="ion-padding ion-float-right"
                    icon={chevronForwardCircleOutline}
                    onClick={() => {
                      currentDate(1);
                    }}
                  />
                </IonText>
              </IonCol>
            </IonRow>
          </IonCard>

          {listCalendar ? (
            <IonList className="main-card">
              {Object.keys(listCalendar).map((value: any, key: any) => (
                <IonCard className="ion-no-padding" key={key}>
                  <IonItem>
                    <IonLabel className="ion-no-padding">
                      <h4>
                        <strong>{value}</strong>
                      </h4>
                    </IonLabel>
                    <IonLabel
                      slot="end"
                      className="ion-float-end ion-text-right"
                    >
                      <span>
                        <IonBadge  color="dark">
                          {listCalendar[value][0].visit_id}
                        </IonBadge>
                        <br />
                        <IonBadge
                          color={listCalendar[value][0].visit_is_check?"success":"danger"}
                        >
                          {listCalendar[value][0].visit_is_check
                            ? "Periksa"
                            : "Belum Di Periksa"}
                        </IonBadge>
                      </span>
                    </IonLabel>
                  </IonItem>
                  <IonList className="ion-no-padding">
                    {listCalendar[value].map((value: any, key: any) => (
                      <IonItem
                        key={key}
                        onClick={() => {
                          getVisitDetail(value);
                        }}
                      >
                        <IonLabel>
                          {value.customer_name} ({value.customer_id})
                          <span>{value.customer_address1}</span>
                        </IonLabel>
                        <IonLabel slot="end" className="ion-float-end">
                          <span>
                            <IonIcon
                              size="small"
                              className="ion-float-end"
                              color={
                                value.visitdet_is_visit ? "success" : "dangert"
                              }
                              src={
                                value.visitdet_is_visit
                                  ? checkmarkCircleOutline
                                  : closeCircleOutline
                              }
                            />
                            <br />
                            {value.visitdet_is_visit
                              ? "Approve"
                              : "Belum Approve"}
                          </span>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </IonCard>
              ))}
            </IonList>
          ) : (
            <>
              {loaded ? (
                <IonImg
                  className="img-empty"
                  src={"assets/img/search-empty.webp"}
                />
              ) : (
                <IonList>
                  <IonListHeader>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80px" }}
                    ></IonSkeletonText>
                  </IonListHeader>
                  {loadList.map((value: any, key: any) => (
                    <IonItem key={key}>
                      <IonThumbnail slot="start">
                        <IonSkeletonText animated={true}></IonSkeletonText>
                      </IonThumbnail>
                      <IonLabel>
                        <h3>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "80%" }}
                          ></IonSkeletonText>
                        </h3>
                        <p>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "60%" }}
                          ></IonSkeletonText>
                        </p>
                        <p>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "30%" }}
                          ></IonSkeletonText>
                        </p>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    );
};

export default Calendar;
