import {
  IonModal,
  IonContent,
  IonDatetime
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { OverlayEventDetail } from "@ionic/core/components";
import { useTranslation } from "react-i18next";
import { DateProps } from "../../models/Data";
import "./Date.scss";

const InputDate: React.FC<any> = ({
  onCloseModal,
  initialData,
  isOpen,
}: DateProps) => {
  const modalInputDate = useRef<HTMLIonModalElement>(null);
  const [date, setDate] = useState<string | null>(null);
  const { i18n } = useTranslation(["general"]);

  const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
    modalInputDate.current?.dismiss();
  };

  const handleDateChange = (event: CustomEvent) => {
    setDate(event.detail.value);
    onCloseModal(event.detail.value);
    modalInputDate.current?.dismiss();
  };

  useEffect(() => {}, [isOpen,date]);

  return (
    <IonModal
      ref={modalInputDate}
      isOpen={isOpen ?? false}
      initialBreakpoint={0.8}
      breakpoints={[0, 1.0]}
      handleBehavior="cycle"
      onWillDismiss={(ev) => onWillDismiss(ev)}
    >
      <IonContent className="ion-padding">
        <IonDatetime
          presentation="date"
          size="cover"
          showDefaultTitle={true}
          showDefaultButtons={true}
          onIonChange={handleDateChange}
          value={initialData}
          display-format="YYYY-MM-DD"
          locale={i18n.language === "id" ? "id-ID" : "en-US"}
        ></IonDatetime>
      </IonContent>
    </IonModal>
  );
};

export default InputDate;
