import {
    IonPage,
    IonContent,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonBadge,
} from '@ionic/react';
import React, { useState } from 'react';
import { getNameDate, groupBy } from '../../services/Library';
import { RestApi } from '../../services/Api';
import { arrowBackOutline} from 'ionicons/icons';
import { Customer } from '../../models/Modal'
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';
import './BackOrder.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const BackOrder: React.FC<Customer> = (props) =>{
    const [alert] = useIonAlert();
    const { t } = useTranslation(["general"]);
    const [listBackOrder, setListBackOrder] = useState<any>([]);
    const [customer, setCustomer] = useState<any>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const massageAlert = async (status:string,body:string) => {
        alert({
            header: t(status),
            message: body,
            cssClass: `header-alert-${status}`,
            buttons: [{ text: "Ya", cssClass: "alert-button-confirm", role: "confirm" }]
        })
    }

    const getArList = async (page:string) => {
        await RestApi(`${page}`,'get',{},'application/json')
        .then((res:any) => {
            if (res.data.code === 200) {
                setListBackOrder(groupBy(res.data.data, 'so_id'))
                setCustomer({
                    so_customer_name:res.data.data[0].so_customer_name,
                    so_customer_id:res.data.data[0].so_customer_id,
                })
                setLoaded(true)
            }else{
                massageAlert('error',res.data.message)
                props.onDismissDetail()
            }
        })
        .catch((err:any) => {
            props.onDismissDetail()
            massageAlert('error',err.message)
        });
    }

    useConstructor(() => {
        getArList(`so/back-order/${props.idCustomer}`)
    });

    return (
        <IonPage>
            <IonHeader className='toolbar-backorder'>
            {loaded ?
                <IonToolbar className='ion-no-padding'>
                    <IonButton color="light"slot="start" fill="clear" onClick={() => props.onDismissDetail()}>
                    <IonIcon color="light" slot='start' src={arrowBackOutline}/>
                    </IonButton>
                    <IonLabel color='light' className='ion-text-center ion-no-padding'>
                        <h1>Back Order</h1>
                        <h4><strong>{customer.so_customer_name} ({customer.so_customer_id})</strong></h4>
                    </IonLabel >
                    <IonLabel slot='end' className='ion-padding'>
                        <IonBadge color="light" slot='end' >{listBackOrder.length}</IonBadge>
                    </IonLabel>
                </IonToolbar>
            :<IonToolbar> 
                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>
            </IonToolbar>
            } 
            </IonHeader>
            <IonContent className='content-backorder'>
                {!loaded &&
                <IonList>
                    <IonListHeader>
                        <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
                    </IonListHeader>
                    {loadList.map((value:any, key:any) => (
                        <IonItem key={key}>
                            <IonThumbnail slot="start">
                                <IonSkeletonText animated={true}></IonSkeletonText>
                            </IonThumbnail>
                            <IonLabel>
                            <h3>
                                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
                            </h3>
                            <p>
                                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
                            </p>
                            <p>
                                <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
                            </p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                }
                {listBackOrder && loaded ?
                <IonList className="main-card">
                    {Object.keys(listBackOrder).map((keyName, i) => (
                        <IonCard className="ion-padding" key={i}>
                            <IonItem className='ion-no-padding' >
                                <IonLabel className='ion-no-padding'>
                                    <span>{getNameDate(listBackOrder[keyName][0].so_date)}</span>
                                    <h2>
                                        <strong>{listBackOrder[keyName][0].so_id}</strong>
                                    </h2>
                                </IonLabel>
                            </IonItem>
                            <IonCardContent className='ion-no-padding'>
                                {listBackOrder[keyName].map((value:any, key:any) => (
                                    <IonItem className='ion-no-padding' key={key}>
                                        <IonText>
                                            <p>- <strong>{value.sodet_item_name} </strong></p>
                                            <p>Qty </p>
                                            <p><strong>{value.sodet_item_quantity}</strong></p>
                                        </IonText>
                                        <IonText slot='end'>
                                            <p><strong>&nbsp;</strong></p>
                                            <p> Qty Outstanding</p>
                                            <p><strong>{value.sodet_item_outstanding}</strong></p>
                                        </IonText>
                                    </IonItem> 
                                ))}
                            </IonCardContent>
                        </IonCard>
                    ))}
                </IonList>
                :<IonImg className='img-empty' src={"assets/img/search-empty.webp"} />
                }
            </IonContent>
        </IonPage>
    );
};

export default BackOrder;
